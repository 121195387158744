//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is the final page on the bbt platform flow, which maps the product gui
// and displays all select unput boxes
//
// POST /v1/portal/register-payment-source-id
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import styled from "styled-components";

interface guisChoice {
  de?: string;
  en?: string;
  value?: string;
}

interface guis {
  editable?: boolean;
  fact_name: string;
  inc?: number;
  max?: number;
  min?: number;
  required?: boolean;
  text?: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  widget?: string;
  media: string[];
  choice: guisChoice[];
  class: string;
}

type Props = {
  guis: guis;
  singleProduct: any;
  getProductFactsGuiValue: Function;
  referer: string;
};

const ProductChoice: React.FC<Props> = ({ guis, singleProduct, getProductFactsGuiValue, referer }) => {
  /* A hook that is used to get the language state from the redux store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* A hook that is used to set the state of the select box. */
  const [focused, setFocused] = useState(false);

  return (
    <>
      <SelectBoxWrapper className={`SelectElement ${focused ? "SelectElement--focus" : ""}`}>
        <div className={referer}>
          <select
            name="choice"
            id=""
            className="select-box"
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            value={singleProduct?.facts[guis?.fact_name]}
            onChange={(e) => getProductFactsGuiValue(guis?.fact_name, e.target.value)}
          >
            {guis?.choice.map((guiChoice: guisChoice, i: number) => {
              return (
                <option value={guiChoice?.value} key={i}>
                  {englishLanguage ? guis?.title?.en : guis?.title?.de}:{" "}
                  {englishLanguage ? guiChoice?.en : guiChoice?.de}
                </option>
              );
            })}
          </select>
        </div>
      </SelectBoxWrapper>
    </>
  );
};

const SelectBoxWrapper = styled.div`
  width: 100%;
  margin: 0;

  .days-select {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem 0;
    background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(244,244,244,1) 49%, rgba(235,235,235,1) 100%)";
  }

  &.SelectElement {
    /* background: var(--card-background); */

    .sharenow,
    .sandbox,
    .mini,
    .sandbox,
    .ces24 {
      position: relative;
      background: var(--card-background);
      border-radius: var(--radius-components);
      border: 2px solid var(--clr-white);
      box-shadow: var(--box-shadow);

      &--focus {
        border-color: #0ca1e3;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: -5px;
          left: -5px;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          border: 3px solid #cfdce2;
          border-radius: var(--radius-components);
        }
      }

      select {
        border: 0;
        height: 2.5rem;
        width: 100%;
        display: block;
        text-align: center;
        text-align: -moz-center;
        text-align: -webkit-center;
        text-indent: 5px hanging;
        color: var(--clr-primary-1);
        text-transform: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        background: transparent;
        background-image: linear-gradient(45deg, transparent 50%, var(--clr-primary-1) 60%),
          linear-gradient(135deg, var(--clr-primary-1) 40%, transparent 50%) !important;
        background-position: calc(100% - 15px) 18px, calc(100% - 7px) 18px, 100% 0;
        background-size: 8px 8px, 8px 8px;
        background-repeat: no-repeat;

        &:focus-visible {
          outline: none;
        }

        option {
          width: 100%;
          text-transform: capitalize;
        }
      }
    }
  }

  &.SelectElement {
    .manx {
      background: #ff2900;
      position: relative;
      border-radius: 50px;
      /* border: 2px solid var(--clr-white); */
      box-shadow: var(--box-shadow);

      &--focus {
        border-color: #0ca1e3;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: -5px;
          left: -5px;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          border: 3px solid #cfdce2;
          border-radius: var(--radius-components);
        }
      }

      select {
        border: 0;
        height: 2.5rem;
        width: 100%;
        display: block;
        text-align: center;
        text-align: -moz-center;
        text-align: -webkit-center;
        text-indent: 5px hanging;
        color: white;
        text-transform: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        background: transparent;
        background-image: linear-gradient(45deg, transparent 50%, var(--clr-white) 60%),
          linear-gradient(135deg, var(--clr-white) 40%, transparent 50%) !important;
        background-position: calc(100% - 15px) 18px, calc(100% - 7px) 18px, 100% 0;
        background-size: 8px 8px, 8px 8px;
        background-repeat: no-repeat;

        &:focus-visible {
          outline: none;
        }

        option {
          width: 100%;
          text-transform: capitalize;
        }
      }
    }
  }

  .select-box-bodysize {
    padding-left: 28%;
  }

  @media screen and (min-width: 800px) {
    margin: 0 auto;

    ::-webkit-input-placeholder {
      text-align: center;
    }

    select {
      /* padding-left: calc(100% - 100%); */
      /* padding-left: calc(100% - 50em); */
    }
  }
`;

export default ProductChoice;
