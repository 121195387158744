import React from "react";
import styled from "styled-components";
import dateformat from "dateformat";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";

type Props = {
  category: string;
  starttime: Date;
  endtime: Date;
  referer: string;
};

const NoEnabledProducts: React.FC<Props> = ({ category, starttime, endtime, referer }) => {
  /**
   * It takes a date as an argument and returns a string in the format of "day day.month"
   * @param {Date} date - The date to format
   * @returns the value of the dateformat function.
   */

  const configuration = getPartnerConfiguration(referer);

  const mapTailoredConfig = configuration && configuration.SHOW_COMPONENT_BASED_ON_CATEGORY.includes(category);

  const formattedDate = (date: Date) => {
    let value = dateformat(date, "ddd dd.mm");
    return value;
  };

  const handleClick = () => {
    const body = document.querySelector("#available");

    body?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Wrapper>
      <IconWrapper style={{ alignItems: "flex-start" }}>
        {" "}
        <TextWrapper>
          {" "}
          <RoundedIcon />{" "}
          <p>
            Start <span>{formattedDate(starttime)}</span>{" "}
          </p>
        </TextWrapper>
        <Line>
          <div></div>
        </Line>
        <div>
          {" "}
          <RoundedIcon />{" "}
          <p>
            End <span>{formattedDate(endtime)}</span>{" "}
          </p>
        </div>
      </IconWrapper>

      <Border>
        <div>
          <img
            src={
              mapTailoredConfig
                ? `${envStaticHost}/static/${referer}/error/Empty1.png`
                : `${envStaticHost}/static/${referer}/error/Empty2.png`
            }
            alt="no enabled item"
            onClick={handleClick}
          />
        </div>

        <div>
          <h5>
            {mapTailoredConfig
              ? "Create your individual trip with the offers from the list"
              : "Add products and options from the list"}
          </h5>
        </div>
      </Border>
    </Wrapper>
  );
};

const RoundedIcon = styled.div`
  border: 3px solid var(--clr-primary-1);
  height: 20px;
  width: 20px;
  border-radius: var(--radius-50);
  background: var(--clr-white);
  margin-right: 10px;
`;

const Wrapper = styled.div`
  padding: 5px 15px;
  position: relative;
`;

const Line = styled.div`
  height: 18rem;
  margin: 0 auto;
  padding: 0 8.5px;

  div {
    width: 2px;
    background-color: var(--vertical-div);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin: 0 50.5px 1rem;
  @media screen and (min-width: 800px) {
    margin: 0 57px 1rem;
  }

  div {
    display: flex;
  }

  p {
    color: var(--clr-NoEnabledProducts-text);

    span {
      font-family: var(--p-bold);
    }
  }
`;

const Border = styled.div`
  border: 2px dashed var(--dotted-dash);
  height: 15rem;
  border-radius: var(--radius-components);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  background: var(--popup-bg);
  position: absolute;
  top: 0;
  width: 90%;

  h5 {
    text-align: left;
    color: var(--clr-NoEnabledProducts-text);
  }

  div:first-child {
    width: 35%;
    text-align: center;

    img {
      width: 65%;
    }
  }

  div:last-child {
    padding: 0 15px;
    width: 60%;

    p {
      text-align: center;
    }
  }

  @media screen and (min-width: 800px) {
    flex-direction: column;
    height: 15rem;
    justify-content: space-evenly;

    div:first-child {
      width: 100%;

      img {
        height: 100%;
        width: 7rem;
      }
    }

    div:last-child {
      width: 100%;
    }
  }
`;

export default NoEnabledProducts;
