//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds startdate and enddate values on product screen
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { envStaticHost } from "../Utils/windowHelper";

type Props = {
  startDate: string;
  endDate: string;
  referer: string;
};

const ProductPageDateCard: React.FC<Props> = ({ startDate, endDate, referer }) => {
  return (
    <Wrapper className={``}>
      <div className={referer}>
        {" "}
        <h5 style={{ textAlign: "left" }}>
          {" "}
          <strong style={{ textTransform: "uppercase" }}>{startDate}</strong>
        </h5>
        <img
          src={`${envStaticHost}/static/${referer}/icon/calendar.svg`}
          alt="calendar"
          style={{ height: "4rem", margin: "0 0.5rem" }}
        />
        <h5 style={{ textAlign: "right" }}>
          <strong style={{ textTransform: "uppercase" }}>{endDate}</strong>
        </h5>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .etravel_mercedes,
  .etravel_geo {
    width: 100%;
    margin: 0 auto;
    /* background: var(--clr-white); */
    border-radius: var(--radius-components);
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 1rem auto;
    padding: 0px 15px;
    background: var(--clr-primary-1);
    height: 6rem;

    h5 {
      color: var(--clr-white);
    }

    img {
      width: 50%;
      filter: var(--clr-white-filter);

      @media screen and (min-width: 800px) {
        width: 12rem;
      }
    }
  }

  .manx {
    width: 100%;
    margin: 0 auto;
    border-radius: var(--radius-components);
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 1rem auto;
    padding: 0px 15px;
    height: 6rem;
    background: var(--clr-primary-1);

    h5 {
      color: var(--clr-white);
    }

    img {
      width: 50%;
      filter: var(--clr-white-filter);

      @media screen and (min-width: 800px) {
        width: 12rem;
      }
    }
  }

  .sharenow,
  .sandbox,
  .volkswagen,
  .ces24 {
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
    background: var(--clr-white);
    border-radius: var(--radius-components);
    box-shadow: var(--box-shadow);

    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    color: var(--clr-primary-2);
    text-align: center;
    margin: 1rem auto;

    h5 {
      color: var(--clr-primary-1);
    }

    img {
      width: 50%;
      filter: var(--clr-secondary-1-filter);

      @media screen and (min-width: 800px) {
        width: 12rem;
      }
    }
  }

  .mini {
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
    background: var(--clr-white);
    border-radius: var(--radius-components);
    /* box-shadow: var(--box-shadow); */

    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    color: var(--clr-primary-2);
    text-align: center;
    margin: 1rem auto;

    h5 {
      color: var(--clr-primary-1);
    }

    img {
      width: 50%;
      filter: var(--clr-secondary-1-filter);

      @media screen and (min-width: 800px) {
        width: 12rem;
      }
    }
  }

  @media screen and (min-width: 800px) {
    width: 50vw;
  }
`;

export default ProductPageDateCard;
