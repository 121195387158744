//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is a card component shown on the package page, whhich shows different
// products available for the end user
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { MouseEventHandler, CSSProperties } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { formatPrice } from "../Utils/helper";
import { BsPerson } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";
import { MdArrowForwardIos } from "react-icons/md";
import { AiOutlinePlusCircle } from "react-icons/ai";

interface Props {
  link: string;
  onClick: MouseEventHandler<any>;
  singleProduct: any;
  englishLanguage: boolean;
  referer: string;
}

interface guis {
  required?: boolean;
  editable?: boolean;
  fact_name?: string;
  widget?: string;
  class?: string;
  inc?: number;
  max?: number;
  min?: number;
  text?: {
    de: string;
    en: string;
  };
  title?: {
    de: string;
    en: string;
  };
  media?: string;
}

const SingleProductComponentV2: React.FC<Props> = ({ link, onClick, englishLanguage, singleProduct, referer }) => {
  return (
    <Wrapper>
      <Link to={`/experience/${referer}/${link}`}>
        <div className={referer}>
          <SingleProduct onClick={onClick} englishLanguage={englishLanguage} singleProduct={singleProduct} />
        </div>
      </Link>
    </Wrapper>
  );
};

const SingleProduct = ({
  onClick,
  englishLanguage,
  singleProduct,
}: {
  onClick?: MouseEventHandler<any>;
  singleProduct: any;
  englishLanguage: boolean;
}) => {
  const productIsEnabled = singleProduct?.facts?.enabled === "1";
  const productFrameColor = singleProduct?.facts?.frame_color;
  const productFrameLabel = singleProduct?.facts?.frame_label;
  const frameStyle = productFrameColor && ({ border: "1.5px solid " + productFrameColor } as CSSProperties);
  // : ({ border: "1.5px solid transparent" } as CSSProperties);
  const labelStyle = productFrameColor ? ({ background: productFrameColor } as CSSProperties) : undefined;

  const productEnabled = singleProduct?.facts?.enabled === "1";

  // console.log("extendedProduct", extendedProduct);
  // console.log(
  //   "singleProduct-",
  //   singleProduct?.guis?.map((guis: guis) => guis?.widget === "label" && guis?.media)
  // );

  return (
    <div style={frameStyle} className={productEnabled ? "card" : "card available_offers_margin"}>
      {productFrameColor && productFrameLabel ? (
        <div className="frame_label" style={labelStyle}>
          {productFrameLabel}
        </div>
      ) : (
        ""
      )}
      {/* image */}
      <div className="image_container">
        {productIsEnabled ? (
          <>
            <img
              src={singleProduct?.guis?.map((guis: guis) => guis?.widget === "label" && guis.media)}
              alt=""
              className="img"
            />
          </>
        ) : (
          <>
            <div className="card__bg__filter_1"></div>
            <div className="card__bg__filter_2"></div>
            <img
              src={singleProduct?.guis?.map((guis: guis) => guis?.widget === "label" && guis?.media?.[0])}
              alt=""
              className="img"
            />
          </>
        )}
      </div>
      {/* content */}{" "}
      <div className="content">
        <h2
          dangerouslySetInnerHTML={{
            __html: englishLanguage ? singleProduct?.guis[0]?.title?.en : singleProduct?.guis[0]?.title?.de,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: englishLanguage
              ? `${singleProduct?.guis[0]?.text?.en.substring(0, 90)}...`
              : `${singleProduct?.guis[0]?.text?.de.substring(0, 90)}...`,
          }}
        />
        {/* price and details */}
        <div className="price_persons_route">
          <div className="price_and_persons">
            <div className={productIsEnabled ? "price price_disabled_format" : "price price_enabled_format"}>
              {" "}
              <h4>{formatPrice(singleProduct?.cost, englishLanguage)}</h4>
            </div>
            <div className="person_detail">
              <h4>
                {singleProduct?.facts?.count} <BsPerson className="icon_person" />
              </h4>
            </div>
          </div>
          <div style={{ height: "10px", display: "flex", marginRight: "8px" }}>
            <p>{productIsEnabled ? "Edit" : "More"}</p>
            <MdArrowForwardIos />
          </div>
        </div>
      </div>
      {/* delete btn */}
      <div className="delete_btn_container">
        {productIsEnabled ? (
          <div
            style={{
              borderRadius: "50%",
              height: "2rem",
              width: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BsTrash className="trash" onClick={onClick} />
          </div>
        ) : (
          <AiOutlinePlusCircle className="add" onClick={onClick} />
        )}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0.2rem 0;
  padding: 0 15px;
  position: relative;

  @media screen and (min-width: 800px) {
    padding: 0;
  }

  .available_offers_margin {
    margin: 1rem 0;
  }

  .card {
    background: var(--card-background);
    height: 8.5rem;
    border-radius: var(--radius-components);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-shadow: var(--box-shadow);

    @media screen and (min-width: 800px) {
      height: 10rem;
      width: 99%;
      margin: 0 auto;
    }

    .frame_label {
      position: absolute;
      top: -0.7em;
      padding: 0.2em 0.6em;
      margin: 0 0.5em;
      border-radius: 0.8em;
      color: var(--clr-white);
    }

    .content {
      padding: 5px;
      width: 70%;
      background: var(--bg-transparent);
      height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: 8px;

      @media screen and (min-width: 800px) {
        padding-left: 12px;
        width: 75%;
      }

      h2 {
        color: var(--clr-primary-1);
      }

      .price_persons_route {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 95%;

        h4 {
          color: var(--clr-primary-1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: capitalize;
          padding-left: 8px;
        }

        p {
          color: var(--clr-primary-1);
          padding-left: 8px;
        }
      }

      .price_and_persons {
        background: var(--clr-secondary-5);
        border-radius: var(--radius-6px);
        width: 65%;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: 800px) {
          height: 1.7rem;
        }
      }

      .person_detail {
        width: 30%;
        height: inherit;
        display: flex;
        align-items: center;

        h4 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 5px;
          font-size: 12px;
          border-top-right-radius: var(--radius-6px);

          .icon_person {
            margin-left: 3px;
          }
        }
      }

      .price {
        border-radius: var(--radius-6px);
        width: 6rem;
        height: 30px;
        display: flex;
        justify-content: center;
        padding: 0 3px;
        align-items: center;
        height: inherit;

        h4 {
          color: var(--clr-white);
          font-size: 12px;
        }
      }
      .price_enabled_format {
        background: var(--clr-secondary-1);
      }

      .price_disabled_format {
        background: var(--clr-primary-1);
      }
    }

    /* trash */
    .delete_btn_container {
      .trash {
        color: var(--clr-trash);
        font-size: 22px;
      }

      .add {
        color: var(--clr-add);
        font-size: 2rem;

        @media screen and (min-width: 800px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    .card {
      background-color: var(--clr-white);

      .image_container {
        width: fit-content;
        position: relative;

        .card__bg__filter_1,
        .card__bg__filter_2 {
          width: 100%;
          height: 97%;
          position: absolute;
          top: 0;
          left: 0;
        }

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          @media screen and (min-width: 800px) {
            width: 8.5rem;
            height: 8.5rem;
          }
        }
      }

      .card__bg__filter_1,
      .card__bg__filter_2,
      img {
        border-radius: var(--radius-components);
        border: 2px solid var(--clr-white);
      }

      .content {
        h2,
        p {
          color: var(--clr-black);
        }

        .price_persons_route {
          p {
            font-weight: bold;
            padding-right: 6px;
          }
        }

        .person_detail {
          display: none;
        }

        .price_and_persons {
          width: 40%;
        }

        .price {
          background: var(--clr-primary-1);
          h4 {
            color: var(--clr-white);
          }
        }
      }
    }
  }

  .manx {
    .card {
      background-color: var(--clr-primary-1);

      .image_container {
        width: fit-content;
        position: relative;

        .card__bg__filter_1,
        .card__bg__filter_2 {
          width: 100%;
          height: 97%;
          position: absolute;
          top: 0;
          left: 0;
        }

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          @media screen and (min-width: 800px) {
            width: 8.5rem;
            height: 8.5rem;
          }
        }
      }

      .card__bg__filter_1,
      .card__bg__filter_2,
      img {
        border-radius: var(--radius-components);
        border: 2px solid var(--clr-white);
      }

      .content {
        h2,
        p {
          color: var(--clr-white);
        }

        .person_detail {
          display: none;
        }

        .price_and_persons {
          width: 40%;
        }

        .price {
          background: var(--clr-white);
          h4 {
            color: var(--clr-black);
          }
        }
      }
    }
  }

  .volkswagen {
    .card {
      .image_container {
        width: fit-content;
        position: relative;

        .card__bg__filter_1,
        .card__bg__filter_2 {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .card__bg__filter_1 {
          background-image: linear-gradient(rgba(110, 163, 186, 0) 90%, rgba(0, 84, 120, 1) 100%);
        }

        .card__bg__filter_2 {
          opacity: 0.5;
          background-color: var(--clr-primary-1);
        }

        img {
          width: 100px;
          height: 100px;
          /* border-radius: var(--radius-50); */
          object-fit: cover;
          border: 2px solid var(--clr-primary-1);
          @media screen and (min-width: 800px) {
            width: 8.5rem;
            height: 8.5rem;
          }
        }
      }

      .card__bg__filter_1,
      .card__bg__filter_2,
      img {
        border-radius: var(--radius-components);
        border: 2px solid var(--clr-white);
      }

      .content {
        h2,
        p {
          color: var(--clr-white);
        }

        .price {
          background: var(--clr-white);
          h4 {
            color: var(--clr-primary-1);
          }
        }
      }
    }
  }

  .sharenow,
  .sandbox,
  .ces24 {
    .card {
      .image_container {
        width: fit-content;
        position: relative;
        /* height: 72%; */

        .card__bg__filter_1,
        .card__bg__filter_2 {
          width: 100%;
          height: 97%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .card__bg__filter_1 {
          background-image: linear-gradient(rgba(110, 163, 186, 0) 90%, rgba(0, 84, 120, 1) 100%);
        }

        .card__bg__filter_2 {
          opacity: 0.5;
          background-color: var(--clr-primary-1);
        }

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;

          @media screen and (min-width: 800px) {
            width: 8.5rem;
            height: 8.5rem;
          }
        }
      }

      .card__bg__filter_1,
      .card__bg__filter_2,
      img {
        border-radius: var(--radius-50);
        border: 2px solid var(--clr-primary-1);
      }
    }

    .content {
      .person_detail {
        h4 {
          color: var(--clr-primary-1);
        }
      }
    }
  }

  .mini {
    .image_container {
      width: fit-content;
      position: relative;

      .card__bg__filter_1,
      .card__bg__filter_2 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .card__bg__filter_1 {
        background-image: linear-gradient(rgba(110, 163, 186, 0) 90%, rgba(0, 84, 120, 1) 100%);
      }

      .card__bg__filter_2 {
        opacity: 0.5;
        background-color: var(--clr-primary-1);
      }

      img {
        width: 100px;
        height: 100px;
        /* border-radius: var(--radius-50); */
        object-fit: cover;
        border: 2px solid var(--clr-primary-1);
        @media screen and (min-width: 800px) {
          width: 8.5rem;
          height: 8.5rem;
        }
      }
    }
    .card {
      .card__bg__filter_1,
      .card__bg__filter_2,
      img {
        border-radius: var(--radius-components);
      }
    }

    .content {
      .person_detail {
        h4 {
          color: var(--clr-white);
        }
      }
    }
  }
`;

export default SingleProductComponentV2;
