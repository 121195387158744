import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";

type Props = {
  handleChange: Function;
  packagesInterests:
    | {
        factname: string;
        media: string;
        title: {
          en: string;
          de: string;
        };
      }[]
    | undefined;
  facts: any;
};

interface int {
  factname: string;
  media: string;
  title: {
    en: string;
    de: string;
  };
}

const Interest: React.FC<Props> = ({ handleChange, packagesInterests, facts }) => {
  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage: boolean = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  const configuration = getPartnerConfiguration(facts.referer);

  return (
    <Wrapper>
      <div className={facts?.referer}>
        {configuration && configuration.SHOW_COMPONENT_BASED_ON_CATEGORY.includes(facts.category) && (
          <Heading className="section-center">
            {englishLanguage ? "What are you into ?" : "Was Interessiert Dich ?"}
          </Heading>
        )}

        {configuration &&
          !configuration.SHOW_COMPONENT_BASED_ON_CATEGORY.includes(facts.category) &&
          facts?.referer !== "mini" && (
            <Heading className={`section-center`}>
              {englishLanguage ? "PICK CAR EQUIPMENT FOR..." : "ICH SUCHE FAHRZEUGAUSSTATTUNG FÜR..."}
            </Heading>
          )}

        <CardWrapper className={"categories-selector"}>
          <BtnWrapper>
            {/* <div className={facts?.referer}> */}
            {packagesInterests?.map((int: int, idx: number) => (
              <Button
                key={idx}
                className={facts?.[int.factname] === "1" ? "inverse" : "normal"}
                onClick={() => {
                  handleChange(int.factname, facts?.[int.factname] === "1" ? "0" : "1");
                }}
              >
                <img src={`${envStaticHost}${int.media}`} alt="interest" />
                <p>{englishLanguage ? int?.title?.en : int?.title?.de}</p>
              </Button>
            ))}
            {/* </div> */}
          </BtnWrapper>
        </CardWrapper>
      </div>
    </Wrapper>
  );
};

const Heading = styled.h5``;

const CardWrapper = styled.div`
  padding: 0 15px 1rem;
  overflow: scroll;
  display: flex;
  align-items: center;
  height: 6.5rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 800px) {
    width: 50vw;
    margin: 0 auto;
    padding: 0 0 1rem;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  transition: var(--transition);
  margin: 0 -0.2rem; /* canceling out the button margins at the left/right ends */
`;

const Button = styled.button.attrs({
  type: "button",
})`
  height: 5rem;
  width: 4.5rem;
  border: none;
  cursor: pointer;
  border-radius: var(--radius-components);
  margin: 0 0.2rem;
  transition: var(--transition);
  padding: 8px;

  img {
    height: 25px;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 10px;
    color: var(--clr-primary-1);
    font-family: var(--p-medium);
  }
`;

const Wrapper = styled.div`
  .sharenow,
  .sandbox,
  .mini {
    ${Button} {
      box-shadow: var(--box-shadow);
    }
    ${Heading} {
      color: var(--clr-primary-1);
      margin-bottom: 25px;
      font-family: var(--p-medium);
      text-transform: uppercase;

      @media screen and (min-width: 800px) {
        width: 50vw;
        margin: 0 auto;
      }
    }
    .inverse {
      img {
        filter: var(--clr-white-filter);
      }

      z-index: 1;
      background: var(--clr-primary-1);

      p {
        color: var(--clr-white);
      }
    }

    .normal {
      img {
        filter: var(--clr-primary-1-filter);
      }

      p {
        color: var(--clr-primary-1);
      }

      background: var(--clr-white);
    }
  }

  .volkswagen {
    ${Button} {
      box-shadow: var(--box-shadow);
    }
    ${Heading} {
      color: var(--clr-white);
      margin-bottom: 25px;
      font-family: var(--p-medium);
      text-transform: uppercase;

      @media screen and (min-width: 800px) {
        width: 50vw;
        margin: 0 auto;
      }
    }

    .inverse {
      img {
        filter: var(--clr-primary-1);
      }

      z-index: 1;
      background: var(--clr-white);

      p {
        color: var(--clr-primary-1);
      }
    }

    .normal {
      img {
        filter: var(--clr-white-filter);
      }

      p {
        color: var(--clr-white);
      }

      background: var(--clr-primary-1-filter);
    }
  }

  .manx {
    ${Heading} {
      color: var(--clr-heading-text);
      margin-bottom: 25px;
      font-family: var(--p-medium);
      text-transform: uppercase;

      @media screen and (min-width: 800px) {
        width: 50vw;
        margin: 0 auto;
      }
    }

    .inverse {
      img {
        filter: var(--clr-white-filter);
      }

      p {
        color: var(--clr-white-text);
      }

      background: #ff2900;
    }

    .normal {
      img {
        filter: var(--clr-primary-1);
      }

      z-index: 1;
      background: var(--clr-white);

      p {
        color: var(--clr-primary-1);
      }
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    ${Heading} {
      color: var(--clr-heading-text);
      margin-bottom: 25px;
      font-family: var(--p-medium);
      /* text-transform: uppercase; */

      @media screen and (min-width: 800px) {
        width: 50vw;
        margin: 0 auto;
      }
    }

    .inverse {
      img {
        filter: var(--clr-white-filter);
      }

      p {
        color: white;
      }

      background: #0088c5;
    }

    .normal {
      img {
        filter: brightness(0) saturate(100%);
      }

      /* z-index: 1; */
      background: var(--clr-white);

      p {
        color: black;
      }
    }
  }

  .ces24 {
    display: none;
  }
`;

export default Interest;
