//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page calls two endpoints. and also handles customisation of packages through
// number of children and adults
//
// POST /v1/portal/login
// POST /v1/portal/select-packages

// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { Error, Hero, Loading, Button, CategoryToggleBtn, ExperienceLanding, ButtonLoading } from "../components";
import { useNavigate } from "react-router-dom";
import { getPackagesAction, updatePackagesAction, getAIRecommendationAction } from "../State/ActionCreators/index";
import styled from "styled-components";
import { DesktopWrapper, WhiteBackground, CustomBtnWidth, ButtonSticky } from "../Utils/styles";
import { UPDATE_FACTS } from "../State/ActionTypes/facts";
import { envStaticHost } from "../Utils/windowHelper";
import { backToTop, writeBackup } from "../Utils/helper";
import { resetLocalStorage } from "../Utils/helper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface products {
  product_id: string;
  facts: any;
  cost: number;
  currency: string;
  guis: guis[];
  latest_cancel: string;
}

interface categories {
  en: string;
  de: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

export interface packageProps {
  package_id: string;
  product_groups: product_groups[];
  categories: categories[];
  facts: any;
  guis: guis[];
}

interface interestProps {
  factname: string;
  media: string;
  title: {
    en: string;
    de: string;
  };
}

const LandingPageScreen = () => {
  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();
  /* Using the useNavigate hook to navigate to a different page. */
  const navigate = useNavigate();

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* Creating a state variable called packages and setting it to an array of packageProps. */
  const [packages, setPackages] = useState<packageProps[]>();

  /* Checking if the packages array is empty. */
  const noPackagesToDisplay = packages?.length === 0;

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts: packageProps["facts"] = useSelector((state: RootState) => {
    return state.facts;
  });

  /* Destructuring the state object and assigning the values to the variables. */
  const getPackagesUpdatedLanding = useSelector((state: RootState) => {
    return state.getPackagesLandingUpdate;
  });

  const { packagesv2 } = getPackagesUpdatedLanding;

  /* Destructuring the state object and assigning the values to the variables. */
  const getPackages = useSelector((state: RootState) => {
    return state.getPackagesList;
  });

  const {
    loading,
    error,
    packages: updatedPackages,
    interests,
  }: {
    loading: boolean;
    error: boolean;
    packages: packageProps[];
    interests: interestProps[];
  } = getPackages;

  /* Using the useSelector hook to get the updated packages from the Redux store. */
  const getUpdatedPackages = useSelector((state: RootState) => {
    return state.getUpdatedPackages;
  });

  const {
    loading: loadingUpdatedPackages,
    interests: interestsFromUpdate,
    error: errorUpdatedPackages,
    success: successUpdatedPackages,
  }: {
    loading: boolean;
    error: boolean;
    success: boolean;
    interests: interestProps[];
    // updatedPackages: any;
  } = getUpdatedPackages;

  // eslint-disable-next-line
  // const [isBtnDisable, setBtnDisable] = useState<boolean>(false);

  /**
   * If the language is English and the loading is not in progress, then return the English string. If
   * the language is German and the loading is not in progress, then return the German string. If the
   * loading is in progress, then return the loading component
   */
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  /**
   * It takes a string and returns a hash of that string
   * @param {string} text - The text to be hashed.
   * @returns A hash of the text.
   */
  const createHashFromFacts = (text: string): string => {
    let hash = 0,
      i,
      chr;
    if (text.length === 0) return "";
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
  };

  /**
   * It's a function that calls the getPackagesAction() function from the Redux store, which in turn
   * calls the getPackages() function from the API, which in turn calls the getPackages() function from
   * the database
   */
  const callSelectPackagesOnLandingControl = useCallback(() => {
    if (Object.keys(facts).length > 0) {
      setTimeout(() => {
        dispatch(
          getPackagesAction({
            facts,
          })
        );
      }, 500);
    }
  }, [dispatch, facts]);

  /**
   * It takes a packageProps object as an argument, and if the facts object has more than 0 keys, it
   * dispatches an updatePackagesAction action with the packageProps object and a combine_packages
   * boolean
   * @param {packageProps} packages - packageProps
   */
  const callUpdatePackagesOnLandingControl = useCallback(
    (packages: packageProps[]) => {
      if (Object.keys(facts).length > 0) {
        dispatch(
          updatePackagesAction({
            packages: packages,
            combine_packages: false,
          })
        );
      }
    },
    [dispatch, facts]
  );

  /**
   * If the hash of the facts in local storage is different from the hash of the facts in local storage,
   * then call the select packages function, otherwise call the update packages function
   */
  const controlledSelectAndUpdateRequest = useCallback(() => {
    /* Getting the hashedFacts from localStorage. */

    const hashedFacts = localStorage.getItem("hashedFacts");
    /* Creating a hash from the facts in local storage. */
    const createdHash = createHashFromFacts(localStorage.getItem("facts") ?? "");

    if (createdHash !== hashedFacts || !hashedFacts) {
      localStorage.setItem("hashedFacts", createdHash);
      callSelectPackagesOnLandingControl();
    }

    if (createdHash === hashedFacts) {
      callUpdatePackagesOnLandingControl(packagesv2);
      setPackages(packagesv2);
    }
  }, [callSelectPackagesOnLandingControl, callUpdatePackagesOnLandingControl, packagesv2]);

  useEffect(() => {
    controlledSelectAndUpdateRequest();
  }, [facts, dispatch, controlledSelectAndUpdateRequest]);

  useEffect(() => {
    const errCount = JSON.parse(localStorage.getItem("err") || "null");
    if (errorUpdatedPackages && errCount < 3) {
      callSelectPackagesOnLandingControl();

      setTimeout(() => {
        callUpdatePackagesOnLandingControl(packagesv2);
      }, 500);
    }
  }, [errorUpdatedPackages, callSelectPackagesOnLandingControl, callUpdatePackagesOnLandingControl, packagesv2]);

  useEffect(() => {
    /* Checking if the data is loading, if there is an error, or if the data is not updated. */
    if (loading || error || !updatedPackages) {
      return;
    }

    /* Setting the state of the packages to the updatedPackages. */
    setPackages(updatedPackages);
    // this was a successful select-packages request, so it's time to query the ai
    dispatch(getAIRecommendationAction());
  }, [updatedPackages, loading, error, dispatch]);

  /* Updating the state of the facts object. */
  const handleChange = useCallback(
    (key, value) => {
      dispatch({
        type: UPDATE_FACTS,
        payload: {
          [key]: value,
        },
      });
    },
    [dispatch]
  );

  // temporary hack for city
  let manipulatedCity = facts?.business_area;

  if (manipulatedCity === "München" && facts?.language === "en") {
    manipulatedCity = "Munich";
  }

  const _buttonState = () => {
    if (loadingUpdate) {
      return <ButtonLoading title={englishLanguage ? "Creating your itinerary" : "Erstellung Deines Reiseverlaufs"} />;
    }
    if (facts?.referer === "manx") {
      return "Let's move on"; // This text will be shown regardless of the language if the referer is 'manx'
    }
    return englishLanguage ? "Continue" : "Weiter";
  };

  const buttonState = _buttonState();

  const getUpdatedPackagesFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem("packages") as string);
  };

  const getPackageIds = (packages: any[]) => {
    return packages?.map((updated: any) => updated.package_id);
  };

  const navigateToPackages = (pack_id: string[]) => {
    if (!loadingUpdatedPackages) {
      if (pack_id.length > 1 || pack_id.length === 0) {
        navigate("*");
      } else {
        setLoadingUpdate(false);
        navigate(`/experience/${facts?.referer}/packages/${pack_id[0]}`);
      }
    }
  };

  const waitForPackageIdChange = async (initialPackId: string[]) => {
    let hasChanged = false;
    let updatedPackId: string[] = [""];

    while (!hasChanged) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const updatedPackages = getUpdatedPackagesFromLocalStorage();
      updatedPackId = getPackageIds(updatedPackages);
      hasChanged = JSON.stringify(initialPackId) !== JSON.stringify(updatedPackId);
    }

    return updatedPackId;
  };

  /**
   * This function handles a click event, updates packages, waits for the package ID to change, and
   * navigates to the updated package.
   */
  const handleClick = async () => {
    setLoadingUpdate(true);

    try {
      const initialEnabledPackages = getUpdatedPackagesFromLocalStorage();
      const initialPackId = getPackageIds(initialEnabledPackages);

      dispatch(
        updatePackagesAction({
          packages: initialEnabledPackages,
          combine_packages: true,
        })
      );

      const updatedPackId = await waitForPackageIdChange(initialPackId);
      setLoadingUpdate(false);

      navigateToPackages(updatedPackId);
      backToTop();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    /* The below JSX is a React component that is responsible for rendering the landing page of the
    BBT experience. */

    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          <Hero
            showCloseBar={false}
            showLanguageBar={true}
            onChange={handleChange}
            language={facts.language}
            vehicle={facts.vehicle}
            business_area={facts.business_area}
            cityBackdropAction={true}
            // defaultBackdropAction={false}
            flow={facts?.flow}
            isFinalOverviewPage={false}
            isLandingPage={true}
            category={facts.category}
            referer={facts.referer}
            facts={facts}
          />

          {error ? (
            <>
              <Error
                errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
                headerText={
                  englishLanguage
                    ? "Sorry, a server problem has occurred"
                    : "Entschuldigung, es ist ein Serverproblem aufgetreten."
                }
                bodyText={englishLanguage ? "Please refresh the page" : "Bitte aktualisieren die Seite"}
              />
              <CustomBtnWidth>
                <Button
                  title="Refresh"
                  onClick={() => {
                    resetLocalStorage();
                    window.location.reload();
                  }}
                />
              </CustomBtnWidth>
            </>
          ) : !error && loading ? (
            <Loading />
          ) : (
            <>
              <CategoryToggle className={"category-toggle"}>
                <CategoryToggleBtn
                  englishLanguage={englishLanguage}
                  handleChange={handleChange}
                  category={facts?.category}
                />
              </CategoryToggle>

              <ExperienceLanding
                facts={facts}
                englishLanguage={englishLanguage}
                handleChange={handleChange}
                packages={packages}
                noPackagesToDisplay={noPackagesToDisplay}
                navigate={navigate}
                packagesInterests={interests || interestsFromUpdate}
              />

              {!noPackagesToDisplay && (
                <WhiteBackground>
                  <CustomBtnWidth>
                    <ButtonSticky>
                      <Button title={buttonState} onClick={handleClick} />
                    </ButtonSticky>
                  </CustomBtnWidth>
                </WhiteBackground>
              )}
            </>
          )}
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  scroll-behavior: smooth;

  /* TO BE REMOVED */
  /* height: 90vh;
  overflow: hidden; */
  /* TO BE REMOVED */

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 800px) {
    .landingPageDesktop {
      width: 50vw;
      margin: 0 auto;
    }
  }

  .filterWrapper {
    padding-left: 2vw; /* corresponding to two times 1vw from inside the PackageControl */
    width: 100%;

    & > div {
      padding: 0;
    }

    p {
      margin: 0;
    }
    .input-range {
      margin: 0.5rem auto 0.5rem;

      @media screen and (min-width: 800px) {
        padding: 0.5rem auto 0.5rem;
        margin: 0;
      }
      h5 {
        margin: 0.3rem 0;
      }
      input {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        height: 4px;
        border-radius: 10px;
      }
      input::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 1.2rem;
        width: 1.2rem;
        background: var(--clr-primary-1);
        box-shadow: 0px 2px 5px #00000021;
        color: var(--clr-primary-1);
        border: 3px solid var(--clr-white);
        opacity: 1;
        border-radius: var(--radius-50);
      }
      input::-moz-range-thumb {
        -moz-appearance: none;
        height: 1.5rem;
        width: 1.5rem;
        background: var(--clr-primary-1);
        box-shadow: 0px 2px 5px #00000021;
        border: 3px solid var(--clr-white);
        opacity: 1;
        border-radius: var(--radius-50);
      }
    }

    .choice-container {
    }

    .amount-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        flex: 1;

        &:first-child {
          margin-right: 1vw;
        }

        &:last-child {
          margin-left: 1vw;
        }
      }
    }

    .num-travellers {
      color: var(--clr-primary-3);
      margin: 2rem auto 1rem;
    }

    .relax-active {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0 2rem;

      @media screen and (min-width: 800px) {
        padding: 0.5rem 0 2rem;
        margin: 0;
      }

      p {
        text-transform: capitalize;
      }
    }

    .amount-container {
      letter-spacing: 1px;
    }

    @media screen and (min-width: 800px) {
      width: 50vw;
      margin: 0 auto;

      .choice-container {
        margin-bottom: 0;
      }

      .amount-container {
        margin-bottom: 0;
      }

      .amount-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          flex: 1;
          margin: 1rem 0;

          &:first-child {
          }

          &:last-child {
          }
        }
      }
    }
  }
`;

const CategoryToggle = styled.div`
  position: absolute;
  top: -20px;
  left: 18%;
  height: 0;
  z-index: 11;

  @media screen and (min-width: 800px) {
    left: 25%;
  }
`;

export default LandingPageScreen;
