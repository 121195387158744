import React from "react";
import styled from "styled-components";

type Props = {
  productHasEnabled: boolean;
  index?: number;
  dates: string;
};

const ShowDatesOnTimeLine: React.FC<Props> = ({ productHasEnabled, dates }) => {
  return (
    <ShowDatesOnTimeLineWrapper>
      {productHasEnabled && (
        <>
          <div>
            <div></div>
          </div>
          <div>
            <p>{dates}</p>
          </div>
        </>
      )}
    </ShowDatesOnTimeLineWrapper>
  );
};

const ShowDatesOnTimeLineWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 65.5px;

  div:last-child {
    p {
      color: var(--clr-PopUpPackage-h4);
      font-family: var(--p-medium);
      font-weight: bold;
    }
  }

  div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      border: 3px solid var(--clr-primary-1);
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: var(--clr-white);
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 800px) {
    padding: 0 72.5px;
  }
`;

export default ShowDatesOnTimeLine;
