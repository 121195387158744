//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this component helps to switch between the experiences landing page and
//  equipment landing page
//

// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";

type Props = {
  englishLanguage: boolean;
  category: string;
  handleChange: Function;
};

const CategoryToggleBtn: React.FC<Props> = ({ englishLanguage, category, handleChange }) => {
  return (
    <Wrapper>
      <form>
        <PackageSelectWrapper>
          <label htmlFor="experiences" className={category === "experiences" ? "label active" : "label"}>
            <input
              type="radio"
              value="experiences"
              name="recommendation"
              id="experiences"
              className="input"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange("category", e.target.value);
              }}
            />{" "}
            {englishLanguage ? "Trip creator" : "Erlebnisse"}
          </label>

          <label htmlFor="equipment" className={category === "equipment" ? "label active " : "label "}>
            <input
              type="radio"
              value="equipment"
              name="recommendation"
              id="equipment"
              className="input"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange("category", e.target.value);
              }}
            />{" "}
            {englishLanguage ? "Equipment Shop" : "Zubehör"}
          </label>
        </PackageSelectWrapper>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem 1rem;
`;

const PackageSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--radius-buttons);
  box-shadow: var(--box-shadow);
  background: var(--clr-secondary-gry-2);
  width: 60vw;
  margin: 0 auto;
  height: 2.5rem;
  padding: 0 5px;

  .label {
    padding: 8px 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    width: 100%;
    cursor: pointer;
    transition: var(--transition);
    text-transform: capitalize;
    font-family: var(--p-medium);
    color: var(--clr-primary-1);

    p {
    }
  }

  .active {
    color: var(--clr-primary-1);
    transition: var(--transition);
    background: var(--clr-white);
    font-family: var(--p-bold);
    width: 100%;
    height: 2rem;
    padding: 0 4px;
    border-radius: var(--radius-buttons);
  }

  .input {
    display: none;
  }

  @media screen and (min-width: 800px) {
    width: 30vw;
    margin: 0 auto;
  }
`;

export default CategoryToggleBtn;
