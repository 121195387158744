//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is one of the few components used in the bbt platform
// this component is used in several pages for selection , adding selected values
// like number of adult, days, children...
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { envStaticHost } from "../Utils/windowHelper";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

interface Props {
  decrease: () => void;
  increase: () => void;
  text?: string;
  referer?: string;
}

/**
 * It returns a div with two buttons and a div with the children prop
 * @param  - React.FC<Props> - This is the type of component we're creating. It's a React Functional
 * Component that takes in a Props object.
 * @returns A React component that renders a div with two buttons and a div with the children prop.
 */

const AmountButtons: React.FC<Props> = ({ increase, decrease, children, referer }) => {
  return (
    <Wrapper>
      <div className={referer}>
        <div>
          <button type="button" className="amount-btn amount-btn-minus" onClick={decrease}>
            <AiOutlineMinusCircle />
          </button>
        </div>
        <div className="children">
          <h5 className="amount">{children}</h5>
        </div>
        <div>
          <button type="submit" className="amount-btn amount-btn-plus" onClick={increase}>
            <AiOutlinePlusCircle />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .sharenow,
  .sandbox,
  .volkswagen,
  .mini,
  .ces24 {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background: var(--clr-white);
    /* background: var(--card-background); */
    height: 2.5rem;
    border-radius: var(--radius-components);
    box-shadow: var(--box-shadow);
    border: 2px solid var(--clr-white);
    position: relative;

    &:active {
      /* border-color: var(--clr-secondary-1); */
      border-color: var(--clr-focus);

      &:before {
        z-index: 1;
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #cfdce2;
        border-radius: 20px;
      }
    }

    & > div {
      flex: 1;
      text-align: center;
      padding: 0 1rem;
      z-index: 2;

      &:first-child,
      &:last-child {
        flex: 0;
      }
    }

    .children {
      color: var(--clr-secondary-3);
      padding: 0;
    }

    button {
      border: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: var(--clr-primary-1);
      cursor: pointer;
      font-size: 25px;
      background: var(--clr-primary-1-filter);
    }

    .amount-btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      border-radius: var(--radius-50);
      background: var(--clr-white);
    }

    .amount {
      color: var(--clr-primary-1);
    }

    .amount-icon {
    }
  }

  .manx {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background: #ff2900;
    color: white;
    /* background: var(--card-background); */
    height: 2.5rem;
    border-radius: 50px;
    box-shadow: var(--box-shadow);
    /* border: 2px solid var(--clr-white); */
    position: relative;

    &:active {
      border-color: var(--clr-secondary-1);

      &:before {
        z-index: 1;
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #cfdce2;
        border-radius: 20px;
      }
    }

    & > div {
      flex: 1;
      text-align: center;
      padding: 0 1rem;
      z-index: 2;

      &:first-child,
      &:last-child {
        flex: 0;
      }
    }

    .children {
      /* color: var(--clr-white); */
      padding: 0;
    }

    button {
      border: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: var(--clr-primary-1);
      cursor: pointer;
      font-size: 25px;
      background: var(--clr-primary-1-filter);
    }

    .amount-btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      border-radius: var(--radius-50);
      background: var(--clr-white);
    }

    .amount {
      color: var(--clr-white);
    }

    .amount-icon {
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background: var(--clr-primary-1);
    color: white;
    height: 2.5rem;
    border-radius: var(--radius-6px);
    box-shadow: var(--box-shadow);
    /* border: 2px solid var(--clr-white); */
    position: relative;

    &:active {
      border-color: var(--clr-secondary-1);

      &:before {
        z-index: 1;
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #cfdce2;
        border-radius: 20px;
      }
    }

    & > div {
      flex: 1;
      text-align: center;
      padding: 0 1rem;
      z-index: 2;

      &:first-child,
      &:last-child {
        flex: 0;
      }
    }

    .children {
      /* color: var(--clr-white); */
      padding: 0;
    }

    button {
      border: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: var(--clr-primary-1);
      cursor: pointer;
      font-size: 25px;
      background: var(--clr-primary-1-filter);
    }

    .amount-btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      border-radius: var(--radius-50);
      background: var(--clr-white);
    }

    .amount {
      color: var(--clr-white);
    }

    .amount-icon {
    }
  }
`;
export default AmountButtons;
