//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds the bbt privacy links, imprint,Terms of use etc ...
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import styled from "styled-components";
import { DesktopWrapper } from "../Utils/styles";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";

const Footer = () => {
  const stateFacts = useSelector((state: RootState) => {
    return state.facts;
  });

  const { language, referer } = stateFacts;
  const englishLanguage = language === "en";
  const surfix = englishLanguage ? "en" : "de";
  const text = englishLanguage ? "Imprint" : "Impressum";
  const privacyText = englishLanguage ? "Privacy Policy" : "Datenschutzbestimmungen";
  const termsOfUse = englishLanguage ? "Terms of Use" : "Nutzungsbedingungen";
  const refererName = referer === "mini" ? "" : "/share-now";
  const isMini = referer === "mini" ? "" : surfix;

  return (
    <DesktopWrapper>
      <Wrapper className="section-center footer-header">
        <section className={referer}>
          <div>
            {" "}
            <a href={`https://www.banbutsu.com${refererName}/imprint/${isMini}`} target="_blank" rel="noreferrer">
              {" "}
              {text}
            </a>
          </div>
          |
          <div>
            <a href={`https://www.banbutsu.com${refererName}/privacy/${isMini}`} target="_blank" rel="noreferrer">
              {" "}
              {privacyText}
            </a>
          </div>
          |
          <span className={"hero-header"}>
            <div>
              <a href={`https://www.banbutsu.com${refererName}/terms/${isMini}`} target="_blank" rel="noreferrer">
                {" "}
                {termsOfUse}
              </a>
            </div>
          </span>
        </section>
      </Wrapper>
    </DesktopWrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  .manx {
    background: var(--popup-bg);
    padding-top: 12px;
    margin: 0 auto;
    height: 15vh;
    color: #ff2900;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: "GT Walsheim Bold Trial";
    font-size: 12px;

    div {
      margin: 0 0.5rem;

      a {
        color: #ff2900;
      }
    }

    @media screen and (min-width: 800px) {
      padding: 1rem 0;
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    background: var(--popup-bg);
    padding-top: 12px;
    margin: 0 auto;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: "Mbcorpostitle Regular";
    font-size: 12px;

    div {
      margin: 0 0.5rem;

      a {
        color: black;
      }
    }

    @media screen and (min-width: 800px) {
      padding: 1rem 0;
    }
  }

  .sharenow,
  .sandbox,
  .ces24,
  .sandbox,
  .mini,
  .volkswagen {
    background: var(--popup-bg);
    padding-top: 12px;
    margin: 0 auto;
    height: 15vh;
    color: var(--clr-secondary-gry);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: "GT Walsheim Bold Trial";
    font-size: 12px;

    div {
      margin: 0 0.5rem;

      a {
        color: var(--clr-primary-4);
      }
    }

    @media screen and (min-width: 800px) {
      padding: 1rem 0;
    }
  }
`;
export default Footer;
