import { envStaticHost } from "./Utils/windowHelper";
import { createGlobalStyle } from "styled-components";

export const GlobalMilesStyle = createGlobalStyle`
/* FONTS */
/* GT America */
@font-face {
  font-family: "GT-America-Compressed-Bold-Trial";
  src: url("${envStaticHost}/static/font/GT-America-Compressed-Bold-Trial.otf");
}

@font-face {
  font-family: "GT-America-Compressed-Regular-Trial";
  src: url("${envStaticHost}/static/font/GT-America-Compressed-Regular-Trial.otf");
}

@font-face {
  font-family: "GT-America-Mono-Medium-Trial";
  src: url("${envStaticHost}/static/font/GT-America-Mono-Medium-Trial.otf");
}

@font-face {
  font-family: "GT-America-Standard-Medium-Trial";
  src: url("${envStaticHost}/static/font/GT-America-Standard-Medium-Trial.otf");
}

@font-face {
  font-family: "GT-America-Standard-Regular-Trial";  
  src: url("${envStaticHost}/static/font/GT-America-Standard-Regular-Trial.otf");
}

@font-face {
  font-family: "GT-America-Compressed-Regular-Italic-Trial";  
  src: url("${envStaticHost}/static/font/GT-America-Compressed-Regular-Italic-Trial.otf");
}

@font-face {
  font-family: "GT-America-Standard-Bold-Trial";  
  src: url("${envStaticHost}/static/font/GT-America-Standard-Bold-Trial.otf");
}

/*
===============
Global Miles CSS Variables
===============
*/

:root {
  /* primary color*/
  --clr-primary-1: #000000;
  --clr-PopUpPackage-h4: var(--clr-primary-1);
  --clr-date-text: #000000;
  --clr-primary-1-filter:invert(0%) sepia(97%) saturate(0%) hue-rotate(237deg) brightness(103%) contrast(100%);  
  /* --clr-primary-1-filter:invert(90%) sepia(19%) saturate(343%) hue-rotate(81deg) brightness(92%) contrast(88%);   */
  --clr-primary-2: #000000;
  --clr-date-picker-to: black;
  --clr-primary-3: #5c5c62;
  --clr-primary-4: #000000; /** */
  --clr-primary-5: #ffffff;
  /* --clr-primary-5: #6B94A5; */
  --clr-pack-active: #B2DBBD;

  /* secondary */
  /* --clr-secondary-1: #000000; */
  --clr-secondary-1-filter: invert(90%) sepia(19%) saturate(343%) hue-rotate(81deg) brightness(92%) contrast(88%);
  --clr-secondary-3: #4ca0d0;
  --clr-secondary-5: #A2A2A280;
  --clr-secondary-gry: #cccccc;
  --clr-secondary-gry-2: #e6e6e6;

  /* date component */
  --clr-secondary-gry-3: #fafcfe;
  --clr-secondary-gry-4: #c5c5c5;
  --clr-date-range: #c9e9f3;
  --clr-heading-text: #ffffff;
  --clr-date-background: #ffffff;
  --border-image: linear-gradient(var(--clr-primary-1) -60px, white 170px, white) 1;
    --hr-: #7070704d;
    --box-shadow: 1px 102px 191px 55px rgba(255, 255, 255, 0.74)

  --clr-add: #4bc46d;
  --clr-trash: #ff1414;
  --clr-red: #ff1414;
  --clr-white: #ffffff;
    --clr-card-content: #ffffff;
  --clr-summary-bg: #ffffff;
  --clr-white-text: #ffffff;

  --clr-white-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7497%)
        hue-rotate(241deg) brightness(102%) contrast(101%);
  --clr-black: black;
  --clr-date-weight: normal;
  --date-card-bg: #ffffff;
  --date-card-bg: var(--clr-primary-1);


  /* final overview aka booking status */
  --clr-bs-section-title: var(--clr-primary-3);
  --clr-bs-title: var(--clr-primary-1);
  --clr-bs-subtitle: var(--clr-primary-2);
  --clr-bs-text: var(--clr-primary-4);
  --clr-bs-check-icon: var(--clr-primary-1);
   --clr-bs-stamp-bg: var(--clr-white);

  /* Border radius */
  --radius-6px: 0px;
  --radius-switch: 16px;
  --radius-components: 0px;
    --radius-options-date: 12px;
  --radius-buttons: 0px;
  --radius-50: 50%;
  --radius-popup-img: 50%;


  /* extras */
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* --box-shadow: 0px 0px 20px #d9e8ed; */
  --box-shadow-dark: 0px 0px 36px -8px rgba(0, 0, 0, 0.83);
  --bg-transparent: transparent;
  --card-background: #ffffff;
  --inactive-border: ;
  --vertical-line: var(--clr-primary-1);
  --popup-bg: var(--clr-white);
  --input-element-border: 2px solid var(--clr-secondary-gry-2);
  --input-element-border-radius: var(--radius-6px);
  --popupcard-img-border: 2px solid var(--clr-primary-1);

  /** font family */
  --p-regular: "GT-America-Standard-Regular-Trial";
  --p-regular-italic: "GT-America-Compressed-Regular-Italic-Trial";
  --p-medium: "GT-America-Standard-Medium-Trial";
  --p-bold: "GT-America-Standard-Bold-Trial";
}


/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--clr-white);
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  color: var(--clr-primary-2);
  font-family: "GT-America-Standard-Medium-Trial";
  text-decoration: none;
}

h1 {
  font-family: "GT-America-Compressed-Bold-Trial";
}

h2 {
  font-family: "GT-America-Compressed-Regular-Trial";
}

h3 {
  font-family: "GT-America-Mono-Medium-Trial";
}

h4 {
  font-family: "GT-America-Standard-Medium-Trial";
}

h5,
p {
  font-family: "GT-America-Standard-Medium-Trial";
   color: var( --clr-primary-3);
}

h1 {
  font-size: 1.875rem;
}
h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.125rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.875rem;
}
p {
   font-size: 0.725rem;
}

 select{
  font-family: "GT-America-Standard-Medium-Trial";
  font-size: 0.875rem;
 }

 span{
  font-size: 0.75rem;
  font-family: "GT-America-Standard-Medium-Trial";
 }

@media screen and (min-width: 800px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.975rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #e8e8e8; */
    font-size: 0.875rem;
  }
}

/* GLOBAL CLASSES */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 100%;
  padding: 0 15px;
  /* max-width: var(--max-width); */
}

.btn {
  font-family: "GT-America-Standard-Medium-Trial";
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 12px 0;
  border-radius: var(--radius-buttons);
  border: none;
  background: var(--clr-primary-1);
  /* background: transparent linear-gradient(42deg, #005478 0%, #6ea3ba 100%) 0% 0%
    no-repeat padding-box; */
  color: white;
  opacity: 1;
  transition: var(--transistion);
}

.btn-inactive {
  /* opacity: 0.4; */
  background-color: var(--clr-primary-5);
  transition: var(--transistion);
  border: 1px solid var(--clr-primary-1);
  color: var(--clr-primary-1);
}

.btn:hover {
  background: var(--clr-primary-1);
}

hr {
  background: rgba(76, 160, 208, 1);
  height: 1px;
  border: none;
  margin: 0.5rem 0;
}

/* 800px and above Tablet & Desktop */
@media screen and (min-width: 800px) {
  .section-center {
    width: 60vw;
    padding: 0;
  }
}
`;
/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

export const GlobalSharenowStyle = createGlobalStyle`
/* FONTS */
/* Nudista */
@font-face {
  font-family: "Nudista Bold";
  src: url("${envStaticHost}/static/font/SuitcaseTypeFoundry-NudistaBold.otf");
}

@font-face {
  font-family: "Nudista Light";
  src: url("${envStaticHost}/static/font/SuitcaseTypeFoundry-NudistaLight.otf");
}

@font-face {
  font-family: "Nudista Semi Bold";
  src: url("${envStaticHost}/static/font/SuitcaseTypeFoundry-NudistaSemiBold.otf");
}

/* Asterisk */
@font-face {
  font-family: "AsteriskSansPro Bold";
  src: url("${envStaticHost}/static/font/AsteriskSansPro-Bold.ttf");
}

@font-face {
  font-family: "AsteriskSansPro Bold Italic";
  src: url("${envStaticHost}/static/font/AsteriskSansPro-BoldItalic.ttf");
}

@font-face {
  font-family: "AsteriskSansPro Regular";
  src: url("${envStaticHost}/static/font/AsteriskSansPro-Regular.ttf");
}

@font-face {
  font-family: "AsteriskSansPro Regular Italic";
  src: url("${envStaticHost}/static/font/AsteriskSansPro-RegularItalic.ttf");
}

@font-face {
  font-family: "AsteriskSansPro Semi Bold";
  src: url("${envStaticHost}/static/font/AsteriskSansPro-SemiBold.ttf");
}

@font-face {
  font-family: "AsteriskSansPro Semi Bold Italic";
  src: url("${envStaticHost}/static/font/AsteriskSansPro-SemiBoldItalic.ttf");
}

/* Montserrat */
@font-face {
  font-family: "Montserrat Bold ";
  src: url("${envStaticHost}/static/font/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Regular ";
  src: url("${envStaticHost}/static/font/Montserrat-Regular.ttf");
}
/* END OF FONTS */

/*
===============
Global Sharenow CSS Variables
===============
*/

:root {
  /* primary color*/
  --clr-primary-1: #005478;
  --clr-PopUpPackage-h4: var(--clr-primary-1);
  --vertical-div: #005478;
  --clr-date-text: #005478;
  --clr-summary-primary-1: #005478;
  --clr-summary-top: var(--clr-primary-1);
  --clr-placeholder: black;
    --icon-boarder: #707070;
  --clr-primary-1-filter: brightness(0) saturate(100%) invert(14%) sepia(99%)
        saturate(2875%) hue-rotate(182deg) brightness(94%) contrast(101%);
  --clr-primary-2: #6ea3ba;
  --clr-date-picker-to: #6ea3ba;
  --clr-primary-3: #5c5c62;
  --clr-primary-4: #707070;
  --clr-primary-5: #6B94A5;
  --clr-pack-active: #d9e8ed;

  /* secondary */
  /* --clr-secondary-1: #0ca1e3; */
  --clr-secondary-1-filter: invert(52%) sepia(96%) saturate(3286%) hue-rotate(169deg) brightness(100%) contrast(91%);
  --clr-secondary-3: #4ca0d0;
  --clr-secondary-5: #0ca1e380;
  --clr-secondary-gry: #cccccc;
  --clr-secondary-gry-2: #e6e6e6;

  /* date component */
  --clr-secondary-gry-3: #fafcfe;
  --clr-secondary-gry-4: #c5c5c5;
  --clr-date-range: #c9e9f3;
    --clr-heading-text: #ffffff;
      --clr-date-background: #ffffff;
  --border-image: linear-gradient(var(--clr-primary-1) -60px, white 170px, white) 1;
  --hr-: #7070704d;
  --box-shadow: 1px 102px 191px 55px rgba(255, 255, 255, 0.74);

  --clr-add: #6ea3ba;
  --clr-trash: #ff1414;
  --clr-red: #FF1414;
  --clr-white: #ffffff;
  --clr-card-content: #ffffff;
  --clr-summary-bg: #ffffff;
  --clr-white-text: #ffffff;

  --clr-white-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7497%)
        hue-rotate(241deg) brightness(102%) contrast(101%);
  --clr-black: black;
  --clr-date-weight: normal;
    --date-card-bg: var(--clr-primary-1);



  /* final overview aka booking status */
  --clr-bs-section-title: var(--clr-primary-3);
  --clr-bs-title: var(--clr-primary-1);
  --clr-bs-subtitle: var(--clr-primary-2);
  --clr-bs-text: var(--clr-primary-4);
  --clr-bs-check-icon: var(--clr-primary-1);
   --clr-bs-stamp-bg: var(--clr-white);


  /* Border radius */
  --radius-6px: 6px;
  --radius-switch: 16px;
  --radius-components: 16px;
  --radius-options-date: 16px;
  --radius-buttons: 25px;
  --radius-50: 50%;
  --radius-popup-img: 50%;


  /* hover */
  --clr-primary-hover: #005478;

  /* extras */
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  --box-shadow: 0px 0px 20px #d9e8ed;
  --box-shadow-dark: 0px 0px 36px -8px rgba(0, 0, 0, 0.83);
  --bg-transparent: transparent;
  --card-background: #ffffff;
  --vertical-line: var(--clr-primary-1);
  --popup-bg:  var(--clr-white);
  --clr-payment-bg: #ffffff;
  --inverse-paragraph-text: var(--clr-primary-3);
  /* --inverse-background: var(--generalBackground); */
  --location-height: 5rem;
  --iconset-filter: brightness(0) saturate(100%) invert(19%) sepia(90%) saturate(1544%) hue-rotate(173deg) brightness(96%)
      contrast(103%);
  --input-element-border: 2px solid var(--clr-secondary-gry-2);
  --input-element-border-radius: var(--radius-6px);
  --input-element-border-bottom: 2px solid var(--clr-secondary-gry-2);
  --popupcard-img-border: 2px solid var(--clr-primary-1);
  --dotted-dash: #B4B4B4;

  /** font family */
  --p-regular: "AsteriskSansPro Regular";
  --p-regular-italic: "AsteriskSansPro Regular Italic";
  --p-medium: "AsteriskSansPro Semi Bold";
  --p-bold: "AsteriskSansPro Bold";
}

/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--clr-white);
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  color: var(--clr-primary-2);
  font-family: "AsteriskSansPro Bold";
  text-decoration: none;
}

h1,
h4 {
    font-family: "Nudista Bold";
}

h2,
h3 {
  font-family: "Nudista Semi Bold";
}

h5,
p {
  font-family: "AsteriskSansPro Regular";
   color: var( --clr-primary-3);
}

h1 {
  font-size: 1.875rem;
}
h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.125rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.875rem;
}
p {
   font-size: 0.725rem;
}

 select{
  font-family: "Nudista Semi Bold";
  font-size: 1rem;
 }

 span{
  font-size: 0.75rem;
  font-family: "AsteriskSansPro Bold";
 }

@media screen and (min-width: 800px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.975rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #e8e8e8; */
    font-size: 0.875rem;
  }
}

/* GLOBAL CLASSES */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 100%;
  padding: 0 15px;
  /* max-width: var(--max-width); */
}

.btn {
  font-family: "Nudista Semi Bold";
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 12px 0;
  border-radius: 25px;
  border: none;
  background: var(--clr-primary-1);
  /* background: transparent linear-gradient(42deg, #005478 0%, #6ea3ba 100%) 0% 0%
    no-repeat padding-box; */
  color: white;
  opacity: 1;
  transition: var(--transistion);
}

.btn-inactive {
  /* opacity: 0.4; */
  background-color: var(--clr-primary-5);
  transition: var(--transistion);
}

.btn:hover {
  background: var(--clr-primary-1);
}

hr {
  background: rgba(76, 160, 208, 1);
  height: 1px;
  border: none;
  margin: 0.5rem 0;
}

/* 800px and above Tablet & Desktop */
@media screen and (min-width: 800px) {
  .section-center {
    width: 60vw;
    margin: 0 auto ;
    padding: 0;
  }
}
`;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

export const GlobalMiniStyle = createGlobalStyle`
/* FONTS */
/* Georgia Pro */

@font-face {
  font-family: "ARIAL.TTF";
  src: url("${envStaticHost}/static/mini/fonts/ARIAL.TTF");
}

@font-face {
  font-family: "ARIALBD.TTF";
  src: url("${envStaticHost}/static/mini/fonts/ARIALBD.TTF");
}

@font-face {
  font-family: "GeorgiaPro-CondBold.ttf";
  src: url("${envStaticHost}/static/mini/fonts/GeorgiaPro-CondBold.ttf");
}

@font-face {
  font-family: "ARIALLGTITL.TTF";
  src: url("${envStaticHost}/static/mini/fonts/ARIALLGTITL.TTF");
}

/*
===============
Global Mini CSS Variables
===============
*/

:root {
  /* primary color*/
  --clr-primary-1: #000000;
  --clr-PopUpPackage-h4: var(--clr-primary-1);
  --vertical-div: #000000;
  --clr-date-text: #000000;
  --icon-boarder: #707070;

  --clr-primary-1-filter:invert(0%) sepia(97%) saturate(0%) hue-rotate(237deg) brightness(103%) contrast(100%);  
  /* --clr-primary-1-filter:invert(90%) sepia(19%) saturate(343%) hue-rotate(81deg) brightness(92%) contrast(88%);   */
  --clr-primary-2: #000000;
  --clr-date-picker-to: black;
  --clr-primary-3: #5c5c62;
  --clr-primary-4: #000000; /** */
  --clr-primary-5: #ffffff;
  /* --clr-primary-5: #6B94A5; */
  --clr-pack-active: #B4B4B4;
  /* --clr-ref-background: #E8E8E8; */

  /* secondary */
  /* --clr-secondary-1: #B4B4B4; */
  --clr-focus: #c5c5c5;
  --clr-secondary-1-filter: invert(77%) sepia(0%) saturate(1%) hue-rotate(192deg) brightness(96%) contrast(85%);
  --clr-secondary-3: #4ca0d0;
  --clr-secondary-5: #A2A2A280;
  --clr-secondary-gry: #cccccc;
  --clr-secondary-gry-2: #e6e6e6;

  /* date component */
  --clr-secondary-gry-3: #fafcfe;
  --clr-secondary-gry-4: #c5c5c5;
  --clr-date-range: #c9e9f3;
  --clr-heading-text: #ffffff;
  --clr-date-background: #ffffff;
  --border-image: linear-gradient(var(--clr-primary-1) -60px, white 170px, white) 1;
  --hr-: #7070704d;
  --box-shadow: 1px 102px 191px 55px rgba(255, 255, 255, 0.74);
  --clr-add: #000000;
  --clr-trash: #ff1414;
  --clr-red: #ff1414;
  --clr-white: #ffffff;
  --clr-card-content: #ffffff;
    --clr-summary-bg: #ffffff;
    --clr-white-text: #ffffff;
  --clr-white-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7497%)
        hue-rotate(241deg) brightness(102%) contrast(101%);
  --clr-black: black;
  --clr-date-weight: normal;
  --date-card-bg: var(--clr-primary-1);



  /* final overview aka booking status */
  --clr-bs-section-title: var(--clr-primary-3);
  --clr-bs-title: var(--clr-primary-1);
  --clr-bs-subtitle: var(--clr-primary-2);
  --clr-bs-text: var(--clr-primary-4);
  --clr-bs-check-icon: var(--clr-primary-1);
   --clr-bs-stamp-bg: var(--clr-white);

  /* Border radius */
  --radius-6px: 5px;
  --radius-switch: 16px;
  --radius-components: 5px;
  --radius-options-date: 5px;
  --radius-buttons: 5px;
  --radius-50: 50%;
  --radius-popup-img: 5px;

  /* hover */
  /* --clr-primary-hover: #005478; */

  /* extras */
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* --box-shadow: 0px 0px 20px #d9e8ed; */
  --box-shadow-dark: 0px 0px 36px -8px rgba(0, 0, 0, 0.83);
  --bg-transparent: transparent;
  --card-background: #E8E8E8;
  --card-price-text: #000000;
  --card-price-bg: #ffffff;
  --inactive-border: ;
  --vertical-line: var(--clr-primary-1);
  --popup-bg:  var(--clr-white);
  --clr-payment-bg: #ffffff;
  --inverse-paragraph-text: var(--clr-primary-3);
  /* --inverse-background: var(--generalBackground); */
  --location-height: 5rem;
  --iconset-filter: brightness(0) saturate(100%) invert(19%) sepia(90%) saturate(1544%) hue-rotate(173deg) brightness(96%)
        contrast(103%);
  --input-element-border: 2px solid var(--clr-secondary-gry-2);
  --input-element-border-radius: var(--radius-6px);
  --popupcard-img-border: 2px solid var(--clr-primary-1);
  --input-element-border-bottom: 2px solid var(--clr-secondary-gry-2);
  --dotted-dash: #B4B4B4;

  /** font family */
  --p-regular: "ARIAL.TTF";
  --p-regular-italic: "ARIALLGTITL.TTF";
  --p-medium:"GeorgiaPro-CondBold.ttf";
  /* --p-medium: "ARIAL.TTF"; */
  --p-bold: "ARIALBD.TTF";
}

/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--clr-white);
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}

a {
  color: var(--clr-primary-2);
  font-family: "ARIAL.TTF";
  text-decoration: none;
}

h1 {
  font-family: "GeorgiaPro-CondBold.ttf";
}

h2 {
  font-family: "GeorgiaPro-CondBold.ttf";
}

h3 {
  font-family: "ARIALBD.TTF";
}

h4 {
  font-family: "GeorgiaPro-CondBold.ttf";
}

h5,
p {
  font-family: "ARIAL.TTF";
   color: var( --clr-primary-3);
}

h1 {
  font-size: 1.5rem;
  /* font-size: 1.875rem; */
}
h2 {
  font-size: 1.125rem;
}
h3 {
  font-size: 0.875rem;
  /* font-size: 1.125rem; */
}
h4 {
  font-size: 0.875rem;
  /* font-size: 1rem; */
}
h5 {
  font-size: 0.875rem;
}
p {
   font-size: 0.725rem;
}

 select{
  font-family: "ARIAL.TTF";
  font-size: 1rem;
 }

 span{
  font-size: 0.75rem;
  font-family: "ARIAL.TTF";
 }

@media screen and (min-width: 800px) {
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.975rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #e8e8e8; */
  }
}

/* GLOBAL CLASSES */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 100%;
  padding: 0 15px;
  /* max-width: var(--max-width); */
}

.btn {
  font-family: "ARIAL.TTF";
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 12px 0;
  border-radius: var(--radius-buttons);
  border: none;
  background: var(--clr-primary-1);
  color: white;
  opacity: 1;
  transition: var(--transistion);
}

.btn-inactive {
  /* opacity: 0.4; */
  background-color: var(--clr-primary-5);
  transition: var(--transistion);
  border: 1px solid var(--clr-primary-1);
  color: var(--clr-primary-1);
}

.btn:hover {
  background: var(--clr-primary-1);
}

hr {
  background: rgba(76, 160, 208, 1);
  height: 1px;
  border: none;
  margin: 0.5rem 0;
}

/* 800px and above Tablet & Desktop */
@media screen and (min-width: 800px) {
  .section-center {
    width: 60vw;
    padding: 0;
  }
}
`;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

export const GlobalVWStyle = createGlobalStyle`
/* FONTS */

@font-face {
  font-family: "VW Head Bold";
  src: url("${envStaticHost}/static/volkswagen/fonts/VWHeadBold.otf");
}

@font-face {
  font-family: "VW Head Regular";
  src: url("${envStaticHost}/static/volkswagen/fonts/VWHeadRegular.otf");
}


/*
===============
Global VW CSS Variables
===============
*/

:root {
  /* primary color*/
--clr-primary-1: #071D4D;
--clr-PopUpPackage-h4: var(--clr-primary-1);
--clr-date-text: #071D4D;
--vertical-div: #ffffff;
--icon-boader: #7070704d;


  
  --clr-primary-1-filter:linear-gradient(90deg, rgba(65,88,132,1) 0%, rgba(21,44,88,1) 100%);  
  /* --clr-primary-1-filter:invert(90%) sepia(19%) saturate(343%) hue-rotate(81deg) brightness(92%) contrast(88%);   */
  --clr-primary-2: #000000;
  --clr-date-picker-to: black;
  --clr-summary-primary-1: #ffffff;
  --clr-summary-top: #ffffff;
  --clr-placeholder: #ffffff;
  --clr-primary-3: #5c5c62;
  --clr-primary-4: #000000; /** */
  --clr-primary-5: #ffffff;
  /* --clr-primary-5: #6B94A5; */
  --clr-pack-active: #B4B4B4;
  /* --clr-ref-background: #E8E8E8; */

  /* secondary */
  /* --clr-secondary-1: #B4B4B4; */
  --clr-secondary-1-filter: invert(77%) sepia(0%) saturate(1%) hue-rotate(192deg) brightness(96%) contrast(85%);
  --clr-secondary-3: #4ca0d0;
  --clr-secondary-5: #A2A2A280;
  --clr-secondary-gry: #cccccc;
  --clr-secondary-gry-2: #e6e6e6;

  /* date component */
  --clr-secondary-gry-3: #fafcfe;
  --clr-secondary-gry-4: #c5c5c5;
  --clr-date-range: #c9e9f3;
  --clr-heading-text: #ffffff;
  --clr-date-background: #ffffff;

  --clr-add: #ffffff;
  --clr-trash: #ff1414;
  --clr-red: #ff1414;
  --clr-white: #ffffff;
  --clr-card-content: #ffffff;
  --clr-summary-bg: #ffffff;
  --clr-white-text: #ffffff;
  --clr-white-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7497%)
        hue-rotate(241deg) brightness(102%) contrast(101%);
  --clr-black: black;
  --clr-date-weight: normal;
    --date-card-bg: var(--clr-primary-1);

  --border-image: linear-gradient(var(--clr-primary-1) -60px, white 170px, white) 1;
  --hr-: #7070704d;
  --box-shadow: 1px 102px 191px 55px rgba(255, 255, 255, 0.74);



  /* final overview aka booking status */
  --clr-bs-section-title: var(--clr-primary-1);
  --clr-bs-title: var(--clr-primary-1);
  --clr-bs-subtitle: var(--clr-primary-1);
  --clr-bs-text: var(--clr-primary-1);
  --clr-bs-check-icon: #4baeea;
   --clr-bs-stamp-bg: var(--clr-white);

  /* Border radius */
  --radius-6px: 5px;
  --radius-switch: 16px;
  --radius-components: 12px;
  --radius-options-date: 12px;
  --radius-buttons: 12px;
  --radius-50: 50%;
  --radius-popup-img: 5px;

  /* hover */
  /* --clr-primary-hover: #005478; */

  /* extras */
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  /* --box-shadow: 0px 0px 20px #d9e8ed; */
  --box-shadow-dark: 0px 0px 36px -8px rgba(0, 0, 0, 0.83);
  --bg-transparent: transparent;
  --card-background:  linear-gradient(90deg, rgba(65,88,132,1) 0%, rgba(21,44,88,1) 100%);
  --card-price-text: #000000;
  --card-price-bg: #ffffff;
  --inactive-border: ;
  --vertical-line: var(--clr-white);
  --popup-bg:  #061f4d;
  --clr-payment-bg: ffffff;
  --clr-payment-bg: ffffff;
  --clr-payment-bg: ffffff;
  --location-height: 3rem;
  /* --iconset-filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(317deg) brightness(106%) contrast(103%); */
  --input-element-border: none;
  --input-element-border-radius: 0px;
  --input-element-border-bottom: 2px solid #ffffff;
  --popupcard-img-border: 2px solid var(--clr-white);
  --dotted-dash: var(--clr-white);


  /** font family */
  --p-regular: "VW Head Regular";
  --p-regular-italic: "VW Head Regular";
  --p-medium: "VW Head Regular";
  --p-bold: "VW Head Bold";
}

/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #071D4D;
  /* background: var(--clr-white); */
  font-size: 0.875rem;
  color: var(--clr-white)
}
ul {
  list-style-type: none;
}

a {
  color: var(--clr-primary-2);
  font-family: "VW Head Regular";
  text-decoration: none;
}

h1 {
  font-family: "VW Head Bold";
}

h2 {
  font-family: "VW Head Bold";
}

h3 {
  font-family: "VW Head Bold";
}

h4 {
  font-family: "VW Head Bold";
}

h5,
p {
  font-family: "VW Head Regular";
   color: var( --clr-primary-3);
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.125rem;
}
h3 {
  font-size: 0.875rem;
}
h4 {
  font-size: 0.875rem;
}
h5 {
  font-size: 0.875rem;
}
p {
   font-size: 0.725rem;
}

 select{
  font-family: "VW Head Regular";
  font-size: 1rem;
 }

 span{
  font-size: 0.75rem;
  font-family: "VW Head Regular";
 }

@media screen and (min-width: 800px) {
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.975rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #071D4D;
    /* background: #e8e8e8; */
  }
}

/* GLOBAL CLASSES */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 100%;
  padding: 0 15px;
  /* max-width: var(--max-width); */
}

.btn {
  font-family: "VW Head Regular";
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 12px 0;
  border-radius: var(--radius-buttons);
  border: none;
  background:#4BAEEA;
  color: white;
  opacity: 1;
  transition: var(--transistion);
}

.btn-inactive {
  /* opacity: 0.4; */
  background-color: #A1C9E2;
  transition: var(--transistion);
  border: 1px solid var(--clr-primary-1);
  color: var(--clr-white);
}

.btn:hover {
  background: var(--clr-primary-1);
}

hr {
  background: rgba(76, 160, 208, 1);
  height: 1px;
  border: none;
  margin: 0.5rem 0;
}

/* 800px and above Tablet & Desktop */
@media screen and (min-width: 800px) {
  .section-center {
    width: 60vw;
    padding: 0;
  }
}
`;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

export const GlobalManxStyle = createGlobalStyle`
/* FONTS */

@font-face {
  font-family: "GT Walsheim Bold Trial";
  src: url("${envStaticHost}/static/manx/fonts/GT-Walsheim-Bold-Trial-BF651b7fc737c57.otf");
}

@font-face {
  font-family: "GT Walsheim Regular Trial";
  src: url("${envStaticHost}/static/manx/fonts/GT-Walsheim-Regular-Trial-BF651b7fc71a47d.otf");
}

@font-face {
  font-family: "FormulaCondensed Bold";
  src: url("${envStaticHost}/static/manx/fonts/FormulaCondensed-Bold.otf");
}

/*
===============
Global MANX CSS Variables
===============
*/



:root {
  /* primary color*/
  --clr-primary-1: #FF2900;
  --clr-PopUpPackage-h4: var(--clr-primary-1);
  --clr-summary-primary-1: #FF2900;
    --clr-summary-top: #ff2900
  --clr-placeholder: #FF2900;
  --icon-boarder: white;


  /* --hero-icon-filter: brightness(0) saturate(100%) invert(47%) sepia(100%) saturate(6731%) hue-rotate(1deg) brightness(102%) contrast(108%); */
  --clr-date-text: #ffffff:
  --clr-primary-1-text: #ffffff;
  --clr-primary-1-filter: brightness(0) invert(1) contrast(100%);

  --clr-primary-2: #ffffff;
  --clr-date-picker-to: #ffffff;
  --clr-primary-3: #5c5c62;
  --clr-primary-4: #000000; /** */
  --clr-primary-5: #ffffff;
  /* --clr-pack-active: #B4B4B4; */
  /* --clr-ref-background: #E8E8E8; */

  /* secondary */
  /* --clr-secondary-1: #B4B4B4; */
  --clr-secondary-1-filter: invert(77%) sepia(0%) saturate(1%) hue-rotate(192deg) brightness(96%) contrast(85%);
  --clr-secondary-3: #4ca0d0;
  --clr-secondary-5: #FF2900;
  --clr-secondary-gry: #cccccc;
  --clr-secondary-gry-2: #e6e6e6;

  /* date component */
  --clr-secondary-gry-3: #fafcfe;
  --clr-secondary-gry-4: #c5c5c5;
  --clr-date-range: #c9e9f3;
  --clr-heading-text: #FF2900;
  --clr-date-background: #FF2900;
  --clr-date-text: #ffffff;
  --popUp-card-price: white;
  --border-image: linear-gradient(var(--clr-primary-1) -60px, #FDF2EA 170px, #FDF2EA) 1;
  --hr-: #FF2900;
  --box-shadow-btn-sticky: 0px -4px 15px 45px rgba(253,242,234,0.72); 

  /* --clr-payment-inputbox: #000000; */

  --clr-add: #ffffff;
  --clr-trash: #ffffff;
  --clr-red: #ff1414;
  --clr-white: #ffffff;
  --clr-card-content: #FF2900;
  --clr-white-text: #ffffff;
  --clr-pack-active-text: #ffffff;
  --clr-pack-not-active-text:#ffffff;
  --clr-summary-bg: #FDF2EA;
  --clr-white-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7497%)
        hue-rotate(241deg) brightness(102%) contrast(101%);
  --clr-black: black;
  --clr-date-weight: normal;
    --date-card-bg: var(--clr-primary-1);




  /* final overview aka booking status */
  --clr-bs-section-title: var(--clr-primary-1);
  --clr-bs-title: var(--clr-primary-1);
  --clr-bs-subtitle: var(--clr-primary-1);
  --clr-bs-text: var(--clr-primary-1);
  --clr-bs-check-icon: #FF2900;
   --clr-bs-stamp-bg: var(--clr-white);

  /* Border radius */
  --radius-6px: 5px;
  --radius-switch: 16px;
  --radius-components: 12px;
  --radius-options-date: 30px;
  --radius-buttons: 50px;
  --radius-50: 50%;
  --radius-popup-img: 5px;
  --inverse-text: #FF2900;
  --clr-NoEnabledProducts-text: #FF2900;
  --vertical-div: #FF2900;

  /* hover */

  /* extras */
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --box-shadow-dark: 0px 0px 36px -8px rgba(0, 0, 0, 0.83);
  --bg-transparent: transparent;
  --card-background: #ffffff;
  --card-price-text: #000000;
  --card-price-bg: #ffffff;
  --inactive-border: ;
  --vertical-line: var(--clr-primary-1);
  --popup-bg:  #FDF2EA;
  --clr-payment-bg: ;
  --location-height: 3rem;
  --input-element-border: none;
  --input-element-border-radius: 0px;
  --input-element-border-bottom: 2px solid #FF2900;
  --popupcard-img-border: 2px solid var(--clr-white);
  --dotted-dash: var(--clr-primary-1);
  --iconset-filter: invert(53%) sepia(97%) saturate(4000%) hue-rotate(-10deg) brightness(103%) contrast(101%);



  /** font family */
  --p-regular: "GT Walsheim Regular Trial";
  --p-regular-italic: "GT Walsheim Regular Trial";
  --p-medium: "GT Walsheim Regular Trial";
  --p-bold: "GT Walsheim Bold Trial";
}

/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFF2E9;
  /* background: var(--clr-white); */
  font-size: 0.875rem;
  color: #FF2900
}
ul {
  list-style-type: none;
}

a {
  color: var(--clr-primary-2);
  font-family: "GT Walsheim Regular Trial";
  text-decoration: none;
}

h1 {
  font-family: "GT Walsheim Bold Trial";
}

h2 {
  font-family: "GT Walsheim Bold Trial";
}

h3 {
  font-family: "GT Walsheim Bold Trial";
}

h4 {
  font-family: "GT Walsheim Bold Trial";
}

h5,
p {
  font-family: "GT Walsheim Regular Trial";
   color: var( --clr-primary-3);
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.125rem;
}
h3 {
  font-size: 0.875rem;
}
h4 {
  font-size: 0.875rem;
}
h5 {
  font-size: 0.875rem;
}
p {
   font-size: 0.725rem;
}

 select{
  font-family: "GT Walsheim Regular Trial";
  font-size: 1rem;
 }

 span{
  font-size: 0.75rem;
  font-family: "GT Walsheim Regular Trial"
 }

@media screen and (min-width: 800px) {
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.975rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #071D4D;
  }
}

/* GLOBAL CLASSES */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 100%;
  padding: 0 15px;
}

.btn {
  font-family: "FormulaCondensed Bold";
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  /* margin: auto; */
  height: 50px;
  cursor: pointer;
  font-size: 1.8rem;
  padding: 12px 0;
  border-radius: 30px;
  border: none;
  background:#FF2900;
  color: white;
  opacity: 1;
  transition: var(--transistion);
  box-shadow: 1px 102px 191px 55px rgba(255, 255, 255, 0.74);

}

.btn-inactive {
  /* opacity: 0.4; */
  background-color: #FFA290;
  transition: var(--transistion);
  /* color: #FF2900 */
}

.btn:hover {
  background: var(--clr-primary-1);
}

hr {
  background: rgba(76, 160, 208, 1);
  height: 1px;
  border: none;
  margin: 0.5rem 0;
}

/* 800px and above Tablet & Desktop */
@media screen and (min-width: 800px) {
  .section-center {
    width: 60vw;
    padding: 0;
  }
}
`;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const GlobalMercedesStyle = createGlobalStyle`
/* FONTS */

@font-face {
  font-family: "Mbcorpostitle Bold";
  src: url("${envStaticHost}/static/etravel_mercedes/fonts/mbcorpostitle-bold.otf");
}

@font-face {
  font-family: "Mbcorpostitle Light";
  src: url("${envStaticHost}/static/etravel_mercedes/fonts/mbcorpostitle-light.otf");
}

@font-face {
  font-family: "Mbcorpostitle Regular";
  src: url("${envStaticHost}/static/etravel_mercedes/fonts/mbcorpostitle-regular.otf");
}

@font-face {
  font-family: "CorpoA";
  src: url("${envStaticHost}/static/etravel_mercedes/fonts/corpoA.otf");
}

/*
===============
Global etravel_mercedes CSS Variables
===============
*/



:root {
  /* primary color*/
  --clr-primary-1: #0088C5;
  --clr-PopUpPackage-h4: #000000;
  --clr-summary-top: var(--clr-primary-1);
  --clr-summary-primary-1: black;
  --clr-placeholder: #FF2900;
  --icon-boarder: white;


  /* --hero-icon-filter: brightness(0) saturate(100%) invert(47%) sepia(100%) saturate(6731%) hue-rotate(1deg) brightness(102%) contrast(108%); */
  --clr-primary-1-text: #ffffff;
  --clr-primary-1-filter: brightness(0) invert(1) contrast(100%);

  --clr-primary-2: #0088C5;
  --clr-date-picker-to: black;
  --clr-date-picker: black;
  --clr-primary-3: #5c5c62;
  --clr-primary-4: #000000; /** */
  --clr-primary-5: #ffffff;

  /* secondary */
  /* --clr-secondary-1: #B4B4B4; */
  --clr-secondary-1-filter: invert(77%) sepia(0%) saturate(1%) hue-rotate(192deg) brightness(96%) contrast(85%);
  --clr-secondary-3: #4ca0d0;
  --clr-secondary-5: #0088C5;
  --clr-secondary-gry: #cccccc;
  --clr-secondary-gry-2: #e6e6e6;
/* filter: brightness(0) saturate(100%) invert(37%) sepia(78%) saturate(1492%) hue-rotate(171deg) brightness(86%) contrast(105%); */
  /* date component */
  --clr-secondary-gry-3: #fafcfe;
  --clr-secondary-gry-4: #c5c5c5;
  --clr-date-range: #c9e9f3;
  --clr-heading-text: black;
  --clr-date-background: white;
  --clr-date-text: black;
  --popUp-card-price: white;
  --border-image: linear-gradient(black -60px, #FDF2EA 170px, #FDF2EA) 1;
  --hr-: black;
  /* --box-shadow-btn-sticky: 0px -4px 15px 45px rgba(255,255,255,0.50);  */

  /* --clr-payment-inputbox: #000000; */

  --clr-add: #0088C5;
  --clr-trash: #0088C5;
  --clr-red: #ff1414;
  --clr-white: #ffffff;
  --clr-card-content: #FF2900;
  --clr-white-text: #ffffff;
  --clr-pack-active-text: #ffffff;
  --clr-pack-not-active-text:#ffffff;
  --clr-summary-bg: transparent;
  --clr-white-filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7497%)
        hue-rotate(241deg) brightness(102%) contrast(101%);
  --clr-black: black;
  --clr-date-weight: bold;
  --date-card-bg: var(--clr-primary-1);



  /* final overview aka booking status */
  --clr-bs-section-title: var(--clr-black);
  --clr-bs-title: var(--clr-primary-1);
  --clr-bs-subtitle: black;
  --clr-bs-text: black;
  --clr-bs-check-icon: var(--clr-primary-1);
  --clr-bs-stamp-bg: #ededed; 

  /* Border radius */
  --radius-6px: 5px;
  --radius-switch: 16px;
  --radius-components: 12px;
  --radius-options-date: 5px;
  --radius-buttons: 5px;
  --radius-50: 50%;
  --radius-popup-img: 5px;
  --inverse-text: #0088C5;
  --clr-NoEnabledProducts-text: black;
  --vertical-div: #0088C5;
 --blend: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(242, 242, 242) 90%);
  /* hover */

  /* extras */
  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
  /* --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
  --box-shadow-dark: 0px 0px 36px -8px rgba(0, 0, 0, 0.83);
  --bg-transparent: transparent;
  --card-background: #ffffff;
  --card-price-text: #000000;
  --card-price-bg: #ffffff;
  --inactive-border: ;
  --vertical-line: var(--clr-primary-1);
  --popup-bg:  #F2F2F2;
  --clr-payment-bg: ;
  --location-height: 3rem;
  --input-element-border: none;
  --input-element-border-radius: 0px;
  --input-element-border-bottom: 2px solid black;
  --popupcard-img-border: 2px solid var(--clr-white);
  --dotted-dash: var(--clr-primary-1);
   --iconset-filter: brightness(0) saturate(100%) invert(32%) sepia(96%) saturate(1241%) hue-rotate(171deg) brightness(95%) contrast(106%);



  /** font family */
  --p-regular: "Mbcorpostitle Regular";
  --p-regular-italic: "Mbcorpostitle Regular";
  --p-medium: "Mbcorpostitle Regular";
  --p-bold: "Mbcorpostitle Bold";
  --headerText: 'CorpoA'
}

/*
===============
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F2F2;
  /* background: var(--clr-white); */
  font-size: 0.875rem;
  color: black;
}
ul {
  list-style-type: none;
}

a {
  color: var(--clr-primary-2);
  font-family: "Mbcorpostitle Light";
  text-decoration: none;
}

h1 {
  font-family: "CorpoA";
}

h2 {
  font-family: "Mbcorpostitle Bold";
}

h3 {
  font-family: "Mbcorpostitle Bold";
}

h4 {
  font-family: "Mbcorpostitle Bold";
}

h5,
p {
  font-family: "Mbcorpostitle Regular";
   color: var( --clr-primary-3);
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.125rem;
}
h3 {
  font-size: 0.875rem;
}
h4 {
  font-size: 0.875rem;
}
h5 {
  font-size: 0.875rem;
}
p {
   font-size: 0.725rem;
}

 select{
  font-family: "Mbcorpostitle Regular";
  font-size: 1rem;
 }

 span{
  font-size: 0.75rem;
  font-family: "Mbcorpostitle Regular"
 }

@media screen and (min-width: 800px) {
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.975rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #071D4D;
  }
}

/* GLOBAL CLASSES */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 100%;
  padding: 0 15px;
}

.btn {
  font-family: "CorpoA";
  text-decoration: none;
  width: 100%;
  /* margin: auto; */
  height: 50px;
  cursor: pointer;
  font-size: 1.8rem;
  border-radius: 5px;
  border: none;
  background:#0088c5;
  color: white;
  opacity: 1;
  transition: var(--transistion);
  /* box-shadow: 1px 102px 191px 55px rgba(255, 255, 255, 1); */

}

.btn-inactive {
  /* opacity: 0.4; */
  background-color: #0088c5;
  transition: var(--transistion);
  /* color: #0088c5 */
}

.btn:hover {
  background: var(--clr-primary-1);
}

hr {
  background: rgba(76, 160, 208, 1);
  height: 1px;
  border: none;
  margin: 0.5rem 0;
}

/* 800px and above Tablet & Desktop */
@media screen and (min-width: 800px) {
  .section-center {
    width: 60vw;
    padding: 0;
  }
}
`;
