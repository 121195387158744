//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// The index of the banbutsu platform project
//
// POST /v1/portal/login
// POST /v1/portal/select-packages
// POST /v1/portal/update-packages
// POST /v1/portal/create-booking
// POST /v1/portal/register-payment-source-id
// POST /v1/portal/confirm-booking
//-----------------------------------------------------------------------------

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  GlobalMilesStyle,
  GlobalSharenowStyle,
  GlobalMiniStyle,
  GlobalVWStyle,
  GlobalManxStyle,
  GlobalMercedesStyle,
} from "./global-styles";
import App from "./App";
import { Provider } from "react-redux";
import store from "./State/store";

/* Getting the cookiefacts from the local storage. */
const cookiefacts = JSON.parse(localStorage.getItem("cookiefacts") as string);
const renderSharenow = cookiefacts?.facts?.referer === "sharenow";
const renderSandbox = cookiefacts?.facts?.referer === "sandbox";
const renderCes24 = cookiefacts?.facts?.referer === "ces24";
const renderMiles = cookiefacts?.facts?.referer === "miles";
const renderMini = cookiefacts?.facts?.referer === "mini";
const renderManx = cookiefacts?.facts?.referer === "manx";
const renderMercedes = cookiefacts?.facts?.referer === "etravel_mercedes";
const renderGeo = cookiefacts?.facts?.referer === "etravel_geo";

ReactDOM.render(
  <Provider store={store}>
    {renderSharenow ? (
      <GlobalSharenowStyle />
    ) : renderSandbox ? (
      <GlobalSharenowStyle /> /* Sandbox should look like Sharenow */
    ) : renderCes24 ? (
      <GlobalSharenowStyle />
    ) : renderMiles ? (
      <GlobalMilesStyle />
    ) : renderMini ? (
      <GlobalMiniStyle />
    ) : renderManx ? (
      <GlobalManxStyle />
    ) : renderMercedes ? (
      <GlobalMercedesStyle />
    ) : renderGeo ? (
      <GlobalMercedesStyle />
    ) : (
      <GlobalVWStyle />
    )}
    <App />
  </Provider>,
  document.getElementById("root")
);
