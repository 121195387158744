import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { BsChevronDown } from "react-icons/bs";
import { envStaticHost } from "../Utils/windowHelper";
import { CustomBtnWidth, WhiteBackground } from "../Utils/styles";
import Button from "./Button";
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

type Props = {
  englishLanguage: boolean;
  referer: string;
  setPopUpModal: Function;
  handleChange: Function;
};

const PopUpOptions: FC<Props> = ({ englishLanguage, referer, setPopUpModal, handleChange }) => {
  const option_guis = JSON.parse(localStorage.getItem("option_guis") as string);
  const lang = englishLanguage ? "en" : "de";
  const getSelections = JSON.parse(localStorage.getItem("selections") as string);
  const facts = JSON.parse(localStorage.getItem("facts") as string);

  const [selections, setSelections] = useState<Record<string, number>>(getSelections ?? facts);
  const [selectedValue, setSelectedValue] = useState("");

  /**
   * The above functions handle incrementing and decrementing a count value within a selections object,
   * based on given constraints.
   * @param {string} factName - factName is a string that represents the name of the fact or item being
   * incremented or decremented. It is used as a key in the selections object to update the count for
   * that specific fact or item.
   * @param {number} min - The `min` parameter represents the minimum value that the count can be
   * decremented to.
   * @param {number} max - The `max` parameter represents the maximum value that the count can reach. It
   * is used to determine whether the count should be incremented or not. If the current count is less
   * than the `max` value, the count is incremented, otherwise, it remains the same.
   */
  const handleDropDownChange = (factName: string, value: string) => {
    setSelections((prevSelections: any) => {
      return {
        ...prevSelections,
        [factName]: value,
      };
    });
  };

  const handleIncrement = (factName: string, max: number) => {
    setSelections((prevSelections) => {
      const currentCount = Number(prevSelections[factName] || 0);
      const newCount = currentCount < max ? currentCount + 1 : currentCount;
      return {
        ...prevSelections,
        [factName]: newCount,
      };
    });
  };

  const handleDecrement = (factName: string, min: number) => {
    setSelections((prevSelections) => {
      const currentCount = Number(prevSelections[factName] || 0);
      const newCount = currentCount > min ? currentCount - 1 : currentCount;
      return {
        ...prevSelections,
        [factName]: newCount,
      };
    });
  };

  /* The `sendAllData` function is a callback function that is used to send all the selected data to the
 parent component. It iterates over the `selections` object, which contains the selected values for
 each option, and calls the `handleChange` function (provided as a prop) with the key (fact name)
 and value (selected value) of each option. The `handleChange` function is responsible for updating
 faccts */
  const sendAllData = useCallback(() => {
    localStorage.setItem("selections", JSON.stringify(selections));
    Object.entries(selections).forEach(([key, value]) => {
      handleChange(key, value?.toString());
    });
  }, [handleChange, selections]);

  return (
    <React.Fragment>
      <Head>
        <h4>OPTIONS</h4>
        <div className="closeBtnContainer">
          <img
            src={`${envStaticHost}/static/${referer}/icon/black-round-x.svg`}
            alt=""
            className="closeIcon"
            onClick={() => setPopUpModal(false)}
          />
        </div>
      </Head>

      <Body>
        {option_guis?.map((optionGui: any, idx: number) => {
          switch (optionGui?.widget) {
            case "label":
              return (
                <div key={idx} className="text-container ">
                  <div className={`${referer}`}>
                    {optionGui?.title && (
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: optionGui?.title?.[lang],
                        }}
                      />
                    )}

                    {optionGui?.text && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: optionGui?.text?.[lang],
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            case "number":
              return (
                <Number_ key={idx}>
                  <div className={referer}>
                    <button
                      type="button"
                      className="amount-btn amount-btn-minus"
                      onClick={() => handleDecrement(optionGui?.fact_name, optionGui?.min || 0)}
                    >
                      <AiOutlineMinus />
                    </button>

                    <div className="children">
                      <h5 className="amount">
                        {(selections?.[optionGui?.fact_name] !== undefined
                          ? selections?.[optionGui?.fact_name]
                          : facts?.[optionGui?.fact_name]) || 0}

                        {optionGui?.title?.[lang] && ` ${optionGui?.title?.[lang]}`}
                      </h5>
                    </div>

                    <button
                      type="submit"
                      className="amount-btn amount-btn-plus"
                      onClick={() => handleIncrement(optionGui?.fact_name, optionGui?.max || 4)}
                    >
                      <AiOutlinePlus />
                    </button>
                  </div>
                </Number_>
              );
            case "choice":
              return (
                <div key={idx}>
                  <Dropdown
                    optionGui={optionGui}
                    setSelectedValue={setSelectedValue}
                    choices={optionGui?.choice}
                    handleDropDownChange={handleDropDownChange}
                    lang={lang}
                    // selectedValue={selectedValue}
                    selections={selections}
                    facts={facts}
                  />
                </div>
              );
            case "button":
              return (
                <div key={idx}>
                  <WhiteBackground>
                    <BtnWrapper>
                      <CustomBtnWidth>
                        <Button
                          title={optionGui?.text?.[lang]}
                          onClick={() => {
                            sendAllData();
                            setPopUpModal(false);
                          }}
                        />
                      </CustomBtnWidth>
                    </BtnWrapper>
                  </WhiteBackground>
                </div>
              );
          }
        })}
      </Body>
    </React.Fragment>
  );
};

const Dropdown = ({
  setSelectedValue,
  optionGui,
  handleDropDownChange,
  lang,
  choices,
  // selectedValue,
  selections,
  facts,
}: {
  optionGui: any;
  setSelectedValue: Function;
  lang: string;
  choices: any;
  handleDropDownChange: Function;
  selections: any;
  facts: any;
}) => {
  // Filter the choices to find the one that matches the current selection based on the fact_name.
  const matchedChoice = choices?.filter((choice: any) => choice?.value === selections?.[optionGui?.fact_name])[0];

  // Retrieve the 'firstLoad' flag from localStorage. If it doesn't exist, default to "0" (indicating it's the first load).
  const firstLoadFromLC = localStorage.getItem("firstLoad") || "0";

  // Initialize the 'isFirstLoad' state based on the value retrieved from localStorage.
  const [isFirstLoad, setIsFirstLoad] = useState(firstLoadFromLC);

  const selectedValue = selections[optionGui?.fact_name];

  useEffect(() => {
    if (isFirstLoad === "0") {
      // Call handleDropDownChange with the default value from the first choice.
      handleDropDownChange(optionGui?.fact_name, choices?.[0]?.value);
      // Update the first load status to "1" (indicating subsequent loads).
      setIsFirstLoad("1");
      // Persist the updated first load status to localStorage.
      localStorage.setItem("firstLoad", "1");
    }
  }, [isFirstLoad, handleDropDownChange, optionGui?.fact_name, choices]);

  return (
    <>
      <div style={{ width: "70%", color: "var(--clr-primary-1)" }}>
        <span>{optionGui?.title?.[lang]}</span>
      </div>
      <SelectBoxWrapper>
        <div style={{ width: "70%" }}>
          <h5> {matchedChoice?.[lang] ?? choices?.[0]?.[lang]} </h5>
        </div>

        <select
          name="chocie"
          id=""
          value={selectedValue}
          onChange={(e) => {
            setSelectedValue(e.target.value);
            handleDropDownChange(optionGui?.fact_name, e.target.value);
            console.log(optionGui?.fact_name, e.target.value);
          }}
        >
          {choices?.map((choice: any, idx: number) => {
            return (
              <option value={choice?.value} key={idx}>
                {choice?.[lang]}
              </option>
            );
          })}
        </select>
        <BsChevronDown className="icon" />
      </SelectBoxWrapper>
    </>
  );
};

const Head = styled.div`
  height: 5rem;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    /* background: red; */
    color: var(--clr-primary-1);
  }

  img {
    /* background: red; */
  }
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 5%;
  bottom: 9.5rem;
  width: 90%;
  margin: 0 auto;
  z-index: 4;

  @media screen and (min-width: 800px) {
    width: 100%;
    left: -5px;
  }
`;

const Number_ = styled.div``;

const Body = styled.div`
  padding: 4px 12px 200px;

  ${Number_} {
    .sharenow,
    .sandbox,
    .ces24,
    .mini {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      height: 2.5rem;
      border-radius: var(--radius-components);
      box-shadow: var(--box-shadow);
      padding: 0 12px;

      .amount {
        color: var(--clr-primary-1);

        h5 {
          text-transform: capitalize;
        }
      }

      button {
        border: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--clr-primary-2);
        cursor: pointer;
        font-size: 25px;
        background: var(--clr-primary-1-filter);
      }

      .amount-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: var(--radius-50);
        background: var(--clr-white);
      }
    }

    .volkswagen {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      height: 2.5rem;
      border-radius: var(--radius-components);
      box-shadow: var(--box-shadow);
      padding: 0 12px;

      .amount {
        color: white;

        h5 {
          text-transform: capitalize;
        }
      }

      button {
        border: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--clr-primary-2);
        cursor: pointer;
        font-size: 25px;
        background: var(--clr-primary-1-filter);
      }

      .amount-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: var(--radius-50);
        background: var(--clr-white);
      }
    }
    .manx {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      height: 2.5rem;
      border-radius: 50px;
      box-shadow: var(--box-shadow);
      padding: 0 12px;
      background: #ff2900;

      .amount {
        color: var(--clr-white);

        h5 {
          text-transform: capitalize;
        }
      }

      button {
        border: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--clr-primary-1);
        cursor: pointer;
        font-size: 25px;
      }

      .amount-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: var(--radius-50);
        background: var(--clr-white);
      }
    }

    .etravel_mercedes,
    .etravel_geo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      height: 2.5rem;
      border-radius: 50px;
      box-shadow: var(--box-shadow);
      padding: 0 12px;
      background: #0088c5;

      .amount {
        color: var(--clr-white);

        h5 {
          text-transform: capitalize;
        }
      }

      button {
        border: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: var(--clr-primary-1);
        cursor: pointer;
        font-size: 25px;
      }

      .amount-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: var(--radius-50);
        background: var(--clr-white);
      }
    }
  }

  .text-container {
    .manx {
      h2 {
        font-size: 2rem;
        font-family: "FormulaCondensed Bold";
        /* margin-bottom: 14px; */
        color: var(--inverse-text);
        text-transform: uppercase;
      }

      p {
        line-height: 26px;
        color: var(--inverse-text);
        font-size: 1rem;

        strong {
          color: var(--clr-secondary-3);
        }
      }
    }
  }

  .text-container {
    .sharenow,
    .sandbox,
    .ces24,
    .volkswagen,
    .mini {
      h2 {
        font-size: 2rem;
        color: var(--inverse-text);
      }

      p {
        line-height: 26px;
        color: var(--inverse-text);
        font-size: 1rem;

        strong {
          color: var(--clr-secondary-3);
        }
      }
    }
  }
`;

const SelectBoxWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin: 0;
  background: var(--clr-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--radius-components);
  box-shadow: var(--box-shadow);
  position: relative;
  padding: 0 18px;
  margin: 12px 0;

  img {
    width: 18px;
    height: 18px;
    filter: var(--clr-primary-1-filter);
  }

  h5 {
    color: var(--clr-primary-1);
    text-transform: capitalize;
    padding-right: 4%;
    font-weight: bold;
  }

  span {
    color: var(--clr-primary-1);
    padding: 0 12px;
  }

  .icon {
    font-size: 1rem;
    color: var(--clr-primary-1);
  }

  select {
    position: absolute;
    left: 5px;
    border: 0;
    height: 40px;
    width: 100%;
    display: block;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    text-indent: 5px hanging;
    color: transparent;
    text-transform: none;
    cursor: pointer;
    background: transparent;
    border-radius: 16px;

    &:focus-visible {
      outline: none;
    }
  }
`;

export default PopUpOptions;
