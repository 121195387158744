import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import { LandingPageScreen, SplashScreen, Unauthorized } from "./index";
import { useNavigate } from "react-router";

const Main = () => {
  const navigate = useNavigate();

  const facts: any = useSelector((state: RootState) => {
    return state.facts;
  });

  const { referer, error } = facts;

  const promotedFactExist = "promoted" in facts;

  useEffect(() => {
    // Check if the "promoted" property exists in the "facts" object
    const promotedFactExist = "promoted" in facts;

    // If the "promoted" property does not exist, navigate to the next route
    !promotedFactExist && navigate(`/experience/${referer}`);

    // Check for 'access_denied' in localStorage and navigate to /unauthorized
    const error = localStorage.getItem("error");
    if (error && error === "access_denied") {
      navigate("/unauthorized");
    }
  }, [promotedFactExist, facts, navigate, referer]);

  // check whether the splash page has been here already
  const splashSeen = localStorage.getItem("splash_seen");

  // Check for unauthorized access
  if (error && error === "access_denied") {
    return <Unauthorized />;
  }

  return splashSeen ? <LandingPageScreen /> : <SplashScreen />;
};

export default Main;
