import { Dispatch } from "redux";
import { SingleProductComponentV2 } from "./index";
import styled from "styled-components";
import { writeBackup } from "../Utils/helper";

type Props = {
  packageState: any;
  singleProduct: any;
  englishLanguage: boolean;
  setPackageState: Function;
  dispatch: Dispatch;
  updatePackagesAction: Function;
  createNewResponseObjectProductGui: Function;
  enabledTriggerValue: string;
};

const ProductCard: React.FC<Props> = ({
  packageState,
  singleProduct,
  englishLanguage,
  setPackageState,
  dispatch,
  updatePackagesAction,
  createNewResponseObjectProductGui,
  enabledTriggerValue,
}) => {
  const productEnabled = singleProduct?.facts?.enabled === "1";

  return (
    <div style={{ position: "relative" }}>
      {productEnabled && (
        <Vertical>
          <div className="top-height"></div>
        </Vertical>
      )}

      <SingleProductComponentV2
        key={Math.random().toString(36).slice(8)}
        singleProduct={singleProduct}
        englishLanguage={englishLanguage}
        referer={packageState?.facts?.referer}
        link={`packages/${packageState?.package_id && packageState?.package_id}/product/${
          singleProduct?.product_id && singleProduct?.product_id
        }`}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          event.stopPropagation();

          dispatch(
            updatePackagesAction(
              createNewResponseObjectProductGui(
                {
                  packages: [packageState],
                  combine_packages: true,
                },
                singleProduct?.product_id,
                "enabled",
                enabledTriggerValue
              )
            )
          );

          writeBackup("readStore", [packageState]);

          setPackageState({
            ...packageState,
            facts: {
              ...packageState?.facts,
              enabled: enabledTriggerValue,
            },
          });
        }}
      />

      {productEnabled && (
        <Vertical>
          <div className="bottom-height"></div>
        </Vertical>
      )}
    </div>
  );
};

const Vertical = styled.div`
  width: 100%;
  padding: 0 74.5px;
  margin: 0 auto;

  .top-height {
    height: 0.5rem;
  }
  .bottom-height {
    height: 1.5rem;
  }

  div {
    background: var(--vertical-line);
    z-index: -11;
    width: 2px;
  }

  @media screen and (min-width: 800px) {
    margin: 0 auto;
    width: 100%;
    padding: 0 81px;

    div {
      background: var(--vertical-line);
      width: 2px;
    }
  }
`;

export default ProductCard;
