import React from "react";
import styled from "styled-components";
import { WhiteBackground, CustomBtnWidth } from "../Utils/styles";
import { Button } from "../components";
import { formatPrice } from "../Utils/helper";
import { BsPerson } from "react-icons/bs";
import { envStaticHost } from "../Utils/windowHelper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

type Props = {
  handleAddPackage: () => void;
  englishLanguage: boolean;
  referer: string;
  gui: guis | undefined;
  setPopUpModal: Function;
  lightOnDarkSuffix: string;
  eachPackage: any;
};

const PopUpPackageCard: React.FC<Props> = ({
  handleAddPackage,
  englishLanguage,
  referer,
  gui,
  setPopUpModal,
  lightOnDarkSuffix,
  eachPackage,
}) => {
  return (
    <main style={{ position: "relative" }}>
      <div className="headerImage">
        <img src={gui?.media?.[1]} alt="header" />
        <div className="closeBtnContainer">
          {/* TODO: vw needs a white-on-transparent round x */}
          <img
            src={`${envStaticHost}/static/${referer}/icon/black-round-x.svg`}
            alt=""
            className="closeIcon"
            onClick={() => setPopUpModal(false)}
          />
        </div>
      </div>

      <div className="headerGradient">
        <img alt="header-gradient" src={`${envStaticHost}/static/${referer}/icon/header-gradient.png`} />
      </div>
      {/* a wrapping div for the rest to allow absolute position and z-index */}
      <div className={`headerAndContent${lightOnDarkSuffix}`}>
        <div className={`headerTextContainer${lightOnDarkSuffix}`}>
          <h2
            className="headerText"
            style={{
              fontFamily: referer === "manx" ? "FormulaCondensed Bold" : "",
              textTransform: referer === "manx" ? "uppercase" : "capitalize",
            }}
            dangerouslySetInnerHTML={{ __html: gui ? (englishLanguage ? gui?.title?.en : gui?.title?.de) : "" }}
          />
        </div>

        <p
          className="packageDescription"
          dangerouslySetInnerHTML={{ __html: gui ? (englishLanguage ? gui?.text?.en : gui?.text?.de) : "" }}
        />

        {eachPackage?.product_groups?.map((product_group: any, i: number) => (
          <React.Fragment key={Math.random().toString(36).slice(8)}>
            <SingleProduct singleProduct={product_group?.products[0]} englishLanguage={englishLanguage} />
          </React.Fragment>
        ))}
        <div style={{ height: "8rem" }}></div>
      </div>

      <WhiteBackground>
        <BtnWrapper>
          <CustomBtnWidth>
            <Button
              title={eachPackage?.facts?.enabled === "1" ? "Remove Package" : "Add Package"}
              onClick={handleAddPackage}
            />
          </CustomBtnWidth>
        </BtnWrapper>
      </WhiteBackground>
    </main>
  );
};

const SingleProduct = React.memo(
  ({ englishLanguage, singleProduct }: { singleProduct: any; englishLanguage?: boolean }) => {
    // console.log("singleProduct", singleProduct);

    return (
      <DataWrapper>
        {/* image */}
        <div className="image_container">
          <img
            src={singleProduct?.guis?.map((guis: any) =>
              guis?.widget === "label" && guis.media ? guis.media[0] : guis.media
            )}
            alt=""
            className="img"
          />
        </div>

        {/* content */}
        <div className="content">
          <h4
            dangerouslySetInnerHTML={{
              __html: englishLanguage ? singleProduct?.guis[0]?.title?.en : singleProduct?.guis[0]?.title?.de,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: englishLanguage
                ? `${singleProduct?.guis[0]?.text?.en.substring(0, 90)}...`
                : `${singleProduct?.guis[0]?.text?.de.substring(0, 90)}...`,
            }}
          />

          <div className="price_persons_route">
            <div className="price_and_persons">
              <div className="price price_enabled_format">
                {" "}
                <h4>{formatPrice(singleProduct?.cost, englishLanguage!)}</h4>
              </div>
              <div className="person_detail">
                <h4>
                  {/* {singleProduct?.facts?.count}
                  <BsPerson className="icon_person" /> */}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </DataWrapper>
    );
  }
);

const DataWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--radius-components);
  box-shadow: var(--box-shadow);
  margin: 8px auto;
  padding: 12px;
  background: var(--card-background);
  width: 90%;

  .image_container {
    width: fit-content;
    position: relative;

    img {
      width: 110px;
      height: 110px;
      border-radius: var(--radius-popup-img);
      object-fit: cover;
      border: var(--popupcard-img-border);

      @media screen and (min-width: 800px) {
        width: 90px;
        height: 90px;
      }
    }
  }

  .content {
    padding: 5px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 8px;

    @media screen and (min-width: 800px) {
      padding-left: 12px;
      width: 75%;
    }

    .price_persons_route {
      width: 60%;

      h4 {
        color: var(--clr-primary-1);
        text-transform: capitalize;
        padding-left: 8px;
      }

      @media screen and (min-width: 800px) {
        width: 100%;
      }
    }

    .price_and_persons {
      background: var(--clr-secondary-5);
      border-radius: var(--radius-6px);
      width: 60%;
      height: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 800px) {
        width: 40%;
      }
    }

    .person_detail {
      width: 30%;
      height: inherit;
      display: flex;
      align-items: center;

      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        color: var(--clr-primary-1);
        border-top-right-radius: var(--radius-6px);

        .icon_person {
          margin-left: 3px;
        }
      }
    }

    .price {
      border-radius: var(--radius-6px);
      width: 6rem;
      height: 30px;
      display: flex;
      justify-content: center;
      padding: 0 3px;
      align-items: center;
      height: inherit;

      h4 {
        color: var(--inverse-background);
      }
    }

    .price_enabled_format {
      background: var(--inverse-text);
      color: var(--popUp-card-price);
    }

    .price_disabled_format {
      background: var(--clr-secondary-1);
    }

    h4 {
      color: var(--clr-PopUpPackage-h4);
    }

    p {
      padding: 4px 0;
      color: var(--inverse-paragraph-text);

      @media screen and (min-width: 800px) {
      }
    }
  }
`;

const BtnWrapper = styled.div`
  position: fixed;
  left: 5%;
  top: 75%;
  /* bottom: 9.5rem; */
  width: 90%;
  margin: 0 auto;
  z-index: 4;

  @media screen and (min-width: 800px) {
    width: 100%;
    left: -5px;
  }
`;

export default PopUpPackageCard;
