//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page displays related packages available for selection / deletion according
// the number of adults / children selected on the landing page.
// Customisation of packages hits the update endpoints
//
// POST /v1/portal/update-packages
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  PageTitle,
  Button,
  Hero,
  Error,
  ProductCard,
  ShowDatesOnTimeLine,
  NoEnabledProducts,
  ViewLocationsBtn,
  Loading,
} from "../components/index";
import { updatePackagesAction, getPackagesAction } from "../State/ActionCreators/index";
import { RootState } from "../State/store";
import { useParams } from "react-router";
import { CustomBtnWidth, DesktopWrapper, WhiteBackground, ButtonSticky } from "../Utils/styles";
import { clearAll } from "../Utils/helper";
import { addDays } from "date-fns";
import { backToTop, createNewResponseObjectProductGui } from "../Utils/helper";
import dateformat from "dateformat";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";

interface guis {
  required?: boolean;
  editable?: boolean;
  fact_name?: string;
  widget?: string;
  class?: string;
  inc?: number;
  max?: number;
  min?: number;
  text?: {
    de: string;
    en: string;
  };
  title?: {
    de: string;
    en: string;
  };
  media?: string[];
}

interface products {
  product_id: string;
  facts: any;
  cost: number;
  currency: string;
  guis: guis[];
  latest_cancel: string;
  cost_net: number;
}

interface categories {
  en?: string;
  de?: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface packageProps {
  package_id: string;
  product_groups: product_groups[];
  categories: categories[];
  facts: any;
  guis: guis[];
}

const TimelinePage = () => {
  /* Using the useNavigate hook to navigate to a different page. */
  let navigate = useNavigate();

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* Destructuring the useParams hook from react-router-dom and assigning it to a variable called
packageIdParams. */
  const { packageid: packageIdParams } = useParams<{ packageid?: string }>();

  /* Using the useSelector hook to get the updatedPackages from the Redux store. */
  const getUpdatedPackages = useSelector((state: RootState) => {
    return state.getUpdatedPackages;
  });

  const {
    loading,
    error,
    updatedPackages,
  }: {
    loading: boolean;
    error: boolean;
    updatedPackages: packageProps[];
  } = getUpdatedPackages;

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  const configuration = getPartnerConfiguration(facts.referer);

  const mapTailoredConfig = configuration && configuration.SHOW_COMPONENT_BASED_ON_CATEGORY.includes(facts.category);

  /* Creating a variable called packages and assigning it to the value of the local storage item called
 packages. If there is no local storage item called packages, it will assign it to an empty array. */
  const packages: packageProps[] = JSON.parse(localStorage.getItem("packages") || "[]");

  const [packageState, setPackageState] = useState<any>(() => {
    const singlePackage = packages?.find((item: packageProps) => item.package_id === packageIdParams);
    // console.log(singlePackage);

    if (singlePackage) {
      const product_groups = singlePackage?.product_groups?.map((pg: product_groups) => {
        const { deletable, multiple } = pg;
        const products = pg?.products?.map((product: products) => {
          const { facts: productFacts } = product;

          // compute product start/enddate from package dates and
          // product facts days_offset, days_duration.
          //
          // if one of the optional days_offset or days_duration is
          // undefined, prodStartDate and prodEndDate are undefined too.
          // As a hack, we use Date(-60000) as special value indicating undefined.
          // (taking -60000 (-1 minute), to make sure the negative time survives
          //  to-string-and-back conversion)
          // Sideeffect: this brings the products to the front in the display
          // ordering, see below.
          let prodStartDate = new Date(-60000);
          let prodEndDate = new Date(-60000);
          if (productFacts?.days_offset != null && productFacts?.days_duration != null) {
            prodStartDate = addDays(new Date(singlePackage?.facts?.starttime), Number(productFacts?.days_offset));
            prodEndDate = addDays(prodStartDate, Number(productFacts?.days_duration - 1));
          }

          return {
            ...product,
            facts: {
              ...productFacts,
              prodStartDate,
              prodEndDate,
            },
          };
        });

        return { deletable, multiple, products };
      });

      return {
        ...singlePackage,
        package_id: packageIdParams,
        product_groups,
      };
    } else {
      return {};
    }
  });

  /* Mapping through the product_groups array and then mapping through the products array and then
  flattening the array. */
  const getSingleProduct = packageState?.product_groups
    ?.map((eachProduct: product_groups) => eachProduct?.products?.map((singleProduct: products) => singleProduct))
    .flat()
    .sort((a: products, b: products) => {
      return a.facts.prodStartDate - b.facts.prodStartDate;
    });

  /**
   * It takes a date as an argument and returns a string in the format of "day day.month"
   * @param {Date} date - The date to format
   * @returns the value of the dateformat function.
   */
  const formattedDate = (date: Date | undefined, needYear: boolean) => {
    try {
      const format = needYear ? "ddd dd.mm yyyy" : "ddd dd.mm";
      return dateformat(date, format);
    } catch (error: any) {
      console.log(error);
    }
  };

  /**
   * It takes an array of objects and returns an object with the same objects, but grouped by the same
   * start date
   * @param arr - [packageProps] - this is the array of objects that we want to filter.
   * @returns An object with the key being the date and the value being an array of objects.
   */
  const filterProductsBySameStartDate = (arr: [packageProps]) => {
    const newObj: any = {};

    arr?.forEach((a: packageProps) => {
      // make sure it's a date - the fact may have become a string in the meantime
      let productStartDate = new Date(a?.facts?.prodStartDate);
      let productEndDate = new Date(a?.facts?.prodEndDate);

      const noProductDate = productStartDate.getTime() < 0; // undefined (the 'new Date(-60000)' from above)
      const differentYear = productStartDate.getFullYear() !== productEndDate.getFullYear();
      // include year in date format if start and end is not in the same year
      let formattedStartDateValue = formattedDate(productStartDate, differentYear);
      let formattedEndDateValue = formattedDate(productEndDate, differentYear);

      /* Checking if the start date and end date are the same. If they are the same, it will return the
      start date. If they are not the same, it will return the start date and end date. */
      let startDateValueToEndDateValue = noProductDate
        ? ""
        : productEndDate.getTime() === productStartDate.getTime()
        ? `Only on ${formattedStartDateValue}`
        : formattedStartDateValue + ` ${"to"} ` + formattedEndDateValue;

      if (newObj.hasOwnProperty(startDateValueToEndDateValue)) {
        newObj[startDateValueToEndDateValue] = [...newObj[startDateValueToEndDateValue], a];
      } else {
        newObj[startDateValueToEndDateValue] = [a];
      }
    });
    return newObj;
  };

  /* Filtering the products by the same start date and then getting the single product. */
  const getFilteredProductsBySameDuration = filterProductsBySameStartDate(getSingleProduct);

  /* Setting the filteredProducts state to the value of the getFilteredProductsBySameDuration function. */
  const [filteredProducts, setFilteredProducts] = useState<any>(getFilteredProductsBySameDuration);

  /* Getting the values and keys of the filteredProducts object. */
  const getFilteredProductsValue = Object.values(filteredProducts);
  const getFilteredProductsKeys = Object.keys(filteredProducts);

  /* Checking if all the products are One. */
  const productsEqualOne = getFilteredProductsValue?.map((x: any) => {
    const allProductAreOne = x?.every((pack: packageProps) => pack.facts.enabled === "1");
    return allProductAreOne;
  });

  /* Checking if every products are One. */
  const everyProductIsEqualOne = productsEqualOne?.every((v) => v === true);

  /* Checking if all the products are zero. */
  const productsEqualZero = getFilteredProductsValue?.map((x: any) => {
    const allProductArezero = x?.every((pack: packageProps) => pack.facts.enabled === "0");
    return allProductArezero;
  });

  /* Checking if every products are zero. */
  const everyProductIsEqualZero = productsEqualZero?.every((v) => v === true);

  /* Mapping over an array of objects and returning a boolean value. */
  const someProductsAreDisabled = getFilteredProductsValue?.map((x: any) => {
    const someProductAreZero = x?.some((pack: packageProps) => pack.facts.enabled === "0");
    return someProductAreZero;
  });

  /* Checking if some of the products are disabled. */
  const someProductsAreZero = someProductsAreDisabled?.some((v) => v === true);

  /* Checking for existence enabled products with location data */
  const someEPHaveLocationInner = Object.values(filteredProducts)?.map((x: any) => {
    const have = x?.some(
      (pack: packageProps) => pack.facts.enabled === "1" && pack.facts.location_lat && pack.facts.location_lng
    );
    return have;
  });

  const someEnabledProductsHaveLocation = someEPHaveLocationInner?.some((v) => v === true);

  const [autoUpdateLoading, setAutoUpdateLoading] = useState<boolean>();

  useEffect(() => {
    /**
     * gets the error count in the storage
     */
    const errCount = JSON.parse(localStorage.getItem("err") || "null");
    /**
     * if there is an update error, the select and update process
     * is automatically triggered
     */
    const handleErrorOnUpdatePackages = async () => {
      Object.keys(facts).length > 0 &&
        setTimeout(() => {
          dispatch(
            getPackagesAction({
              facts,
            })
          );

          const readStore = JSON.parse(localStorage.getItem("readStore") || "[]");

          setTimeout(() => {
            if (Object.keys(facts).length > 0) {
              dispatch(
                updatePackagesAction({
                  packages: [...readStore],
                  combine_packages: true,
                })
              );
            }
            setPackageState(readStore[0]);
          }, 500);
        }, 500);
    };

    /**
     * triggers the function if the
     * errorCount is less than 3 and error is true
     */
    if (error && errCount < 3) {
      setAutoUpdateLoading(true);
      handleErrorOnUpdatePackages();
    } else if (!error) {
      setAutoUpdateLoading(false);
    }
  }, [error, dispatch, facts, autoUpdateLoading]);

  useEffect(() => {
    if (loading || error || !updatedPackages?.[0]) {
      return;
    }

    setFilteredProducts(getFilteredProductsBySameDuration);
    setPackageState(updatedPackages[0]);
    // eslint-disable-next-line
  }, [updatedPackages, loading, error]);

  /**
   * If the product_groups array is empty, or if the products array is empty, or if the facts object is
   * empty, or if the enabled property is not equal to 1, then return true
   * @param {packageProps} myPackage - packageProps
   */
  const makeBtnInactive = (myPackage: packageProps) => {
    return !myPackage?.product_groups?.some((pg: product_groups) => {
      return pg?.products[0]?.facts.enabled === "1";
    });
  };

  /**
   * It navigates to the summary page and scrolls to the top of the page
   */
  const toSummaryPage = () => {
    navigate(`/experience/${facts?.referer}/packages/${packageIdParams}/summary-information`);
    backToTop();
  };

  /**
   * It clears the package state and updates the packages state
   */
  const clear = async () => {
    const newResponseObject = clearAll({
      packages: [packageState],
      combine_packages: true,
    });

    setPackageState(newResponseObject.packages[0]);
    dispatch(updatePackagesAction(newResponseObject));
  };

  return (
    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          {error && !autoUpdateLoading ? (
            <>
              <Hero
                showCloseBar={true}
                navigationRoute={() => navigate(`/experience/${facts?.referer}/`)}
                showLanguageBar={false}
                vehicle={facts.vehicle}
                business_area={facts.business_area}
                cityBackdropAction={true}
                defaultBackdropAction={false}
                isFinalOverviewPage={false}
                isLandingPage={false}
                referer={facts.referer}
                facts={facts}
              />

              <Error
                errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
                headerText={
                  englishLanguage
                    ? "Sorry, a server error occured updating your package"
                    : "Entschuldigung, es scheint ein Problem zu geben."
                }
                bodyText={
                  englishLanguage
                    ? "Please go back and try again"
                    : "Bitte gehen Sie zurück und versuchen Sie es erneut"
                }
              />
              <CustomBtnWidth>
                <Button title={englishLanguage ? "Back Home" : "Zur Startseite"} onClick={() => navigate(-1)} />
              </CustomBtnWidth>
            </>
          ) : autoUpdateLoading ? (
            <>
              <Hero
                showCloseBar={true}
                navigationRoute={() => navigate(`/experience/${facts?.referer}/`)}
                showLanguageBar={false}
                vehicle={facts.vehicle}
                business_area={facts.business_area}
                cityBackdropAction={true}
                defaultBackdropAction={false}
                isFinalOverviewPage={false}
                isLandingPage={false}
                referer={facts.referer}
                facts={facts}
              />
              <Error
                errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
                headerText={
                  englishLanguage
                    ? "Sorry, a server error occured updating your package"
                    : "Entschuldigung, es scheint ein Problem zu geben."
                }
                bodyText={
                  englishLanguage ? "Reloading your selected information" : "Nachladen der ausgewählten Informationen"
                }
              />

              <Loading />
            </>
          ) : (
            <React.Fragment key={packageState?.package_id}>
              <DesktopWrapper key={Math.random().toString(36).slice(8)}>
                <Hero
                  showCloseBar={true}
                  showLanguageBar={false}
                  language={facts.language}
                  vehicle={facts.vehicle}
                  business_area={facts.business_area}
                  cityBackdropAction={true}
                  defaultBackdropAction={false}
                  flow={facts?.flow}
                  isFinalOverviewPage={false}
                  isLandingPage={true}
                  isTimelinePage={true}
                  category={facts.category}
                  navigationRoute={() => navigate(`/experience/${facts?.referer}/select`)}
                  referer={facts.referer}
                  facts={facts}
                />

                <div className="pagetitle-checkbox language-header">
                  {facts.referer !== "manx" && (
                    <>
                      <PageTitle
                        activity={
                          facts.referer === "mini"
                            ? ""
                            : mapTailoredConfig
                            ? englishLanguage
                              ? "YOUR ITINERARY"
                              : "DEIN REISEVERLAUF"
                            : englishLanguage
                            ? "CAR EQUIPMENT"
                            : "DEINE FAHRZEUGAUSSTATTUNG"
                        }
                      />
                    </>
                  )}
                </div>
              </DesktopWrapper>

              {
                /* optional location button */
                someEnabledProductsHaveLocation && (
                  <DesktopWrapper>
                    <WhiteBackground>
                      <div className="location-button-container">
                        <section>
                          <ViewLocationsBtn
                            link={`/experience/${facts?.referer}/packages/${packageIdParams}/product-locations`}
                            englishLanguage={englishLanguage}
                            referer={facts.referer}
                          />
                        </section>
                      </div>
                    </WhiteBackground>
                  </DesktopWrapper>
                )
              }

              <DesktopWrapper>
                <WhiteBackground>
                  <div className="cards-container">
                    <section className={everyProductIsEqualOne ? "big-width" : ""}>
                      {everyProductIsEqualZero && (
                        <div key={Math.random().toString(36).slice(8)}>
                          <NoEnabledProducts
                            category={facts?.category}
                            starttime={facts.starttime}
                            endtime={facts.endtime}
                            referer={facts.referer}
                          />
                        </div>
                      )}

                      {
                        <ClearAllContainer>
                          {!everyProductIsEqualZero && (
                            <div className="clearAllWrapper">
                              <button
                                className="clearAll"
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  clear();
                                  setPackageState({
                                    ...packageState,
                                    facts: {
                                      ...packageState?.facts,
                                      enabled: "0",
                                    },
                                  });
                                }}
                              >
                                Clear all
                              </button>
                            </div>
                          )}
                        </ClearAllContainer>
                      }

                      {getFilteredProductsValue?.map((productsData: any, idx: number) => {
                        const hasEnabled = productsData?.some((pack: packageProps) => pack.facts.enabled === "1");
                        return (
                          <React.Fragment key={idx}>
                            {hasEnabled && (
                              <div key={Math.random().toString(36).slice(8)}>
                                <ShowDatesOnTimeLine
                                  productHasEnabled={hasEnabled}
                                  index={idx}
                                  dates={getFilteredProductsKeys[idx]}
                                />
                              </div>
                            )}

                            {productsData?.map((singleProduct: products) => {
                              const enabledProduct = singleProduct?.facts?.enabled === "1";

                              return (
                                <React.Fragment key={Math.random().toString(36).slice(8)}>
                                  {enabledProduct && (
                                    <div className="card-flex">
                                      <ProductCard
                                        packageState={packageState}
                                        singleProduct={singleProduct}
                                        englishLanguage={englishLanguage}
                                        setPackageState={setPackageState}
                                        dispatch={dispatch}
                                        createNewResponseObjectProductGui={createNewResponseObjectProductGui}
                                        updatePackagesAction={updatePackagesAction}
                                        enabledTriggerValue="0"
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </section>

                    {!everyProductIsEqualOne && (
                      <section>
                        {someProductsAreZero && (
                          <div key={Math.random().toString(36).slice(8)}>
                            <DesktopWrapper>
                              <PackageSelectSection>
                                <div className={facts?.referer}>
                                  <h2 id="available">Available Offers</h2>
                                </div>
                              </PackageSelectSection>
                            </DesktopWrapper>
                          </div>
                        )}

                        {getFilteredProductsValue?.map((productsData: any, idx: number) => {
                          return (
                            <React.Fragment key={idx}>
                              {productsData?.map((singleProduct: products) => {
                                const disabledProduct = singleProduct?.facts?.enabled === "0";

                                return (
                                  <React.Fragment key={Math.random().toString(36).slice(8)}>
                                    {disabledProduct && (
                                      <div className="card-flex">
                                        <ProductCard
                                          packageState={packageState}
                                          singleProduct={singleProduct}
                                          englishLanguage={englishLanguage}
                                          setPackageState={setPackageState}
                                          dispatch={dispatch}
                                          createNewResponseObjectProductGui={createNewResponseObjectProductGui}
                                          updatePackagesAction={updatePackagesAction}
                                          enabledTriggerValue="1"
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </section>
                    )}
                  </div>
                </WhiteBackground>
              </DesktopWrapper>

              <WhiteBackground>
                <CustomBtnWidth>
                  <ButtonSticky>
                    <Button
                      title={englishLanguage ? "Book Now" : "Buchen"}
                      onClick={() => toSummaryPage()}
                      disable={makeBtnInactive(packageState)}
                      inactive={!makeBtnInactive(packageState)}
                    />
                  </ButtonSticky>
                </CustomBtnWidth>
              </WhiteBackground>
            </React.Fragment>
          )}
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

// interface cardRenderProps {
//   bigWidth?: boolean;
// }

const ClearAllContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -15px;

  .clearAllWrapper {
    width: 100%;
    margin: 0.7rem 0 0;
    padding: 0 15px;
    display: flex;
    justify-content: end;
    align-items: center;

    .clearAll {
      border: none;
      color: var(--clr-primary-1);
      background: #ededed;
      height: 1.5rem;
      width: 5rem;
      border-radius: 6px;
      font-family: var(--p-bold);
      box-shadow: var(--box-shadow);
    }
  }
`;

const Wrapper = styled.section`
  margin: 0 auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  transition: var(--transition);
  /* background: var(--clr-white); */

  &::-webkit-scrollbar {
    display: none;
  }

  section {
    margin-bottom: 0.5rem;
  }

  .checkbox-container {
    display: none;
  }

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .cards-container {
    /* background: var(--generalBackground); */

    section {
      width: 100%;
      position: relative;
    }
  }

  .location-button-container {
    section {
      width: 100%;
      position: relative;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 800px) {
    section {
      margin-bottom: 0;
    }

    .pageTitleContainer {
      width: 60%;
    }

    .pagetitle-checkbox {
      display: flex;
      flex-direction: row;
      width: 60vw;
      margin: 0 auto;
      background: var(--clr-white);
    }

    .checkbox-container {
      margin: 0 auto;
      background: var(--clr-white);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .btnWrapper {
      width: 60vw;
      margin: 0 auto;
      background: var(--clr-white);
    }

    .cards-container {
      width: 55vw;
      min-height: 30vh;
      margin: 0 auto;
      padding: 1rem 0 0;
      transition: all 0.3s linear;

      section {
        width: 100%;
      }

      .card-flex {
        margin: 0 auto;
      }
    }

    .location-button-container {
      width: 55vw;
      margin: 0 auto;
      display: flex;
      padding: 1rem 0 0;
      transition: all 0.3s linear;

      section {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 801px) {
    .cards-container {
      flex-wrap: wrap;

      section {
        width: 100%;
        margin: 0 auto;
      }
    }
    .location-button-container {
      section {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    .cards-container {
      section {
        width: 90%;
      }
    }
    .location-button-container {
      section {
        width: 90%;
      }
    }
  }

  @media screen and (min-width: 1155px) {
    width: 100%;

    .cards-container {
      flex-wrap: nowrap;

      section {
        margin: 0 auto;
      }

      .big-width {
        width: 100%;
      }
    }
    .location-button-container {
      section {
        width: 100%;
      }
    }
  }
`;

const PackageSelectSection = styled.div`
  padding: 0 15px;
  margin: 2rem 0;
  text-transform: uppercase;

  h2 {
    color: var(--clr-primary-1);
    margin: 0 0 0.5rem;
  }

  .phoneInput-container {
    margin: 1.5rem auto;
  }

  .volkswagen {
    h2 {
      color: var(--clr-white);
    }
  }

  .manx {
    h2 {
      color: var(--clr-primary-1);
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    h2 {
      color: var(--clr-black);
      text-transform: capitalize;
      font-family: var(--p-regular);
    }
  }

  @media screen and (min-width: 800px) {
    margin: 0 auto;
    padding: 0;
    height: 2rem;
    background: var(--popup-bg);
  }
`;
export default TimelinePage;
