//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds generally the page title on every screen
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  headingTwoGray?: string;
  activity?: string;
  activityLowerCase?: string;
}

/* A functional component used as sub-heading that returns a styled div with properties of string. */
const PageTitle: React.FC<Props> = ({ title, activity, headingTwoGray, activityLowerCase }) => {
  return (
    <Wrapper className="section-center">
      <div className="discover">
        {headingTwoGray && <h2> {headingTwoGray}</h2>}
        {activity && <h1 className="activity" dangerouslySetInnerHTML={{ __html: activity }} />}
        {activityLowerCase && <h1 className="activityLower">{activityLowerCase}</h1>}
        {title && <h5 className="title" dangerouslySetInnerHTML={{ __html: title }} />}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  z-index: 11;
  background: var(--inverse-background);

  h5 {
    padding: 12px 0;
    text-transform: lowercase;
    line-height: 1.5;
  }

  .discover {
    text-transform: uppercase;
  }

  .activity {
    color: var(--inverse-text);
    padding: 0.5rem 0 1rem;
    text-transform: uppercase;
  }

  .activityLower {
    width: 80%;
    padding-left: 0;
    text-transform: uppercase;
    color: var(--clr-primary-1);
  }

  @media screen and (min-width: 800px) {
    margin: 32px auto 16px;
    width: 50vw;

    .activityLower {
      width: 50vw;
    }

    .discover {
      width: 50vw;
      margin: 0 auto;
    }
  }
`;

export default PageTitle;
